import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CuentaService {

 config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }
  };
 
  private urlServicios = environment.urlServicios;
  private urlIncode = environment.urlIncode;
  private apiKey = environment.keyIncode;
  
  constructor(private http: HttpClient) { }

  recuperaCodigoCorreo(data: any) {
    return this.http.post(this.urlServicios + 'api/CodigoCorreo', data, this.config);
  }

  getBancos() {
    return this.http.get(this.urlServicios + 'api/Banco/ddl', this.config);
  }
  
  getCiudades(data: any) {
    return this.http.get(this.urlServicios + 'api/Ciudad/ddl/' + data, this.config);
  }
  
  getEstadosPorCP(data: any) {
    return this.http.get(this.urlServicios + 'api/Estado/ddlcp/' + data, this.config);
  }
  
  getEstadosPorPais(data: any) {
    return this.http.get(this.urlServicios + 'api/Estado/ddlpais/' + data, this.config);
  }
  
  getCodigo(data: any) {
    return this.http.post(this.urlServicios + 'api/Codigo', data, this.config);
  }
  
  getSepomex(data: any) {
    return this.http.get(this.urlServicios + 'api/Sepomex/ddl/' + data, this.config);
  }
  
  getGiros() {
    return this.http.get(this.urlServicios + 'api/Giro/ddl', this.config);
  }
  
  getMonedas() {
    return this.http.get(this.urlServicios + 'api/Moneda/ddl', this.config);
  }
  
  getPaises() {
    return this.http.get(this.urlServicios + 'api/Pais/ddl', this.config);
  }
  
  getResidencias() {
    return this.http.get(this.urlServicios + 'api/Residencia/ddl', this.config);
  }
  
  getTiposEmpresa() {
    return this.http.get(this.urlServicios + 'api/TipoEmpresa/ddl', this.config);
  }
  
  getVolumenes() {
    return this.http.get(this.urlServicios + 'api/Volumen/ddl', this.config);
  }
   
  getRangoPrecios() {
    return this.http.get(this.urlServicios + 'api/RangoPrecio/ddl', this.config);
  }
  
  getMontosDiarios() {
    return this.http.get(this.urlServicios + 'api/MontoDiario/ddl', this.config);
  }
  
  getRegistro(data) {
    return this.http.get(this.urlServicios + 'api/Registro/' + data, this.config);
  }
  
  saveRegistro1(data) {
    return this.http.post(this.urlServicios + 'api/RegistroPaso1', data, this.config);
  }
  
  saveRegistro2(data) {
    return this.http.post(this.urlServicios + 'api/RegistroPaso2', data, this.config);
  }
  
  saveRegistro3(data) {
    return this.http.post(this.urlServicios + 'api/RegistroPaso3', data, this.config);
  }
  
  saveRegistro4(data) {
    return this.http.post(this.urlServicios + 'api/RegistroPaso4', data, this.config);
  }
  
  saveRegistro5(data) {
    return this.http.post(this.urlServicios + 'api/RegistroPaso5', data, this.config);
  }
  
  saveCodigo(data) {
    return this.http.post(this.urlServicios + 'api/Codigo/add', data, this.config);
  }
}
