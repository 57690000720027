<app-loader class="loader1" *ngIf="loading"></app-loader>
<div class="page-content" style="background-color: rgb(237,237,237)" *ngIf="step === 100 || step === 0 || step === 1 || step === 2 || step === 3 || step === 4 || step === 5 || step === 7 || step === 9 || step === 10 || step === 6 || step === 8 || step === 500 || step === 600 || step === 700 || step === 800">
    <div class="wizard-v3-content">
        <div class="wizard-form">
            <div class="wizard-header">
                <h3 class="heading" ><img src="assets/images/logoprincipal.png" style="height: 81px;" /></h3>
            </div>
            <form class="form-register" action="#" method="post">
                <div id="form-total" class="wizard clearfix">
                    <div class="steps clearfix">
                        <ul role="tablist">
                            <li role="tab" aria-disabled="false" class="first {{paso1estilo}}">
                                <a id="form-total-t-0" href="#" aria-controls="form-total-p-0">
                                    <div class="title">
                                        <span class="step-icon"><b class="zmdi {{paso1icono}}"></b></span>
                                        <span class="step-text">Contacto</span>
                                    </div>
                                </a>
                            </li>
                            <li role="tab" aria-disabled="false" class="{{paso2estilo}}" >
                                <a id="form-total-t-1" href="#" aria-controls="form-total-p-1">
                                    <div class="title">
                                        <span class="step-icon"><b class="zmdi {{paso2icono}}"></b></span>
                                        <span class="step-text">Datos Titular</span>
                                    </div>
                                </a>
                            </li>
                            <li role="tab" aria-disabled="false" class="{{paso3estilo}}" >
                                <a id="form-total-t-2" href="#" aria-controls="form-total-p-2">
                                    <div class="title">
                                        <span class="step-icon"><b class="zmdi {{paso3icono}}"></b></span>
                                        <span class="step-text">Comercio</span>
                                    </div>
                                </a>
                            </li>
                            <li role="tab" aria-disabled="false" class="{{paso4estilo}}" >
                                <a id="form-total-t-3" href="#" aria-controls="form-total-p-3">
                                    <div class="title">
                                        <span class="step-icon"><b class="zmdi {{paso4icono}}"></b></span>
                                        <span class="step-text">Transaccionalidad</span>
                                    </div>
                                </a>
                            </li>
                            <li role="tab" aria-disabled="false" class="last {{paso5estilo}}" >
                                <a id="form-total-t-4" href="#" aria-controls="form-total-p-4">
                                    <div class="title">
                                        <span class="step-icon"><b class="zmdi {{paso5icono}}"></b></span>
                                        <span class="step-text">Documentos</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="inner" *ngIf="errores" class="error-message_propio">
                        <div class="form-row center">
                            <div class="form-holder form-holder-2"  [innerHtml]="erroresGenerales"></div>
                        </div>
                    </div>
                    <div class="inner" *ngIf="warnings" class="warnings-message_propio">
                        <div class="form-row center">
                            <div class="form-holder form-holder-2"  [innerHtml]="warningsGenerales"></div>
                        </div>
                    </div>
                    <div class="inner" *ngIf="successmsg" class="successmsg-message_propio">
                        <div class="form-row center">
                            <div class="form-holder form-holder-2"  [innerHtml]="successmsgGenerales"></div>
                        </div>
                    </div>
                    <div [ngSwitch]="step">
                        <div *ngSwitchCase=100>
                            <div class="inner">
                                <h3 class="center" >
                                    <img src="assets/images/avion.jpg" class="center" style="height: 72px;width: 117px;" />
                                    <br/>Términos y Condiciones
                                </h3>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-12">Para nosotros es importante que estés informado sobre nuestro servicio, en esta sección te pedimos que leas y atorices los siguientes puntos importantes:</div>
                                    <br/><br/><br/>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-22"><img src="assets/images/bullet_avioncito.png" style="height: 23px;width: 23px;"/>&nbsp;&nbsp;<a href="assets/formatos/aviso.pdf" target="_blank">Aviso de Privacidad</a></div>
                                </div>
                                <div class="form-row ">
                                    <div class="form-holder form-holder-22"><img src="assets/images/bullet_avioncito.png" style="height: 23px;width: 23px;"/>&nbsp;&nbsp;<a href="assets/formatos/geolocalizacion.pdf" target="_blank">Consentimiento de geolocalización</a></div>
                                </div>
                                <div class="form-row ">
                                    <div class="form-holder form-holder-22"><img src="assets/images/bullet_avioncito.png" style="height: 23px;width: 23px;"/>&nbsp;&nbsp;<a href="assets/formatos/ine.pdf" target="_blank">Consentimiento de consulta en INE</a></div>
                                </div>
                                <div class="form-row ">
                                    <div class="form-holder form-holder-22"><img src="assets/images/bullet_avioncito.png" style="height: 23px;width: 23px;"/>&nbsp;&nbsp;<a href="assets/formatos/firmadigital.pdf" target="_blank">Uso de la firma autógrafa digital</a></div>
                                </div>
                                <div class="form-row ">
                                    <div class="form-holder form-holder-22"><img src="assets/images/bullet_avioncito.png" style="height: 23px;width: 23px;"/>&nbsp;&nbsp;<a href="assets/formatos/notificaciones.pdf" target="_blank">Autorización de notificaciones vía correo electrónico y mensajes SMS</a></div>
                                </div>
                                <div class="form-row center">
                                    <div class="form-holder form-holder-12">
                                        <mat-checkbox [(ngModel)]="aceptoTerminos" (ngModelChange)="aceptarTerminos()" [ngModelOptions]="{standalone: true}">
                                                <label>He leído, acepto y autorizo</label>
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div class="form-row center">
                                    <div class="form-holder form-holder-2">
                                        <button class="btnTomarFoto" type="button" (click)="nextStep100()" [disabled]="desTerminos || errores">Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase=1>
                            <div class="inner" id="div2_1">
                                <h3 class="center" >
                                    <img src="assets/images/avion.jpg" class="center" style="height: 72px;width: 117px;" />
                                    <br/>Información de Contacto
                                </h3>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-12">Nos encanta estar en contacto y sobre todo proteger tu seguridad, porfavor compartenos tu correo electrónico, te enviaremos un código de veriificación.</div>
                                    <br/><br/><br/>
                                </div>
                               <div class="form-row">
                                    <div class="form-holder form-holder-22">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Correo Electrónico
                                            </mat-label>
                                            <input matInput type="email" [formControl]="emailControl" required autocomplete="off" (ngModelChange)="validaCamposCorreo()" appBlockCopyPaste>
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="emailControl.touched && emailControl.errors && emailControl.errors.required">El correo electrónico es
                                              requerido</mat-error>
                                            <mat-error *ngIf="emailControl.touched && emailControl.errors && emailControl.errors.pattern">El correo electrónico no es válido
                                            </mat-error>
                                        </div>
                                    </div>
                               </div>
                               <div class="form-row">
                                    <div class="form-holder form-holder-22 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Confirmar Correo Electrónico
                                            </mat-label>
                                            <input matInput type="email" [formControl]="emailConfirmControl" required autocomplete="off" (ngModelChange)="validaCamposCorreo()" appBlockCopyPaste>
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="emailConfirmControl.touched && emailConfirmControl.errors && emailConfirmControl.errors.required">El correo
                                              electrónico es requerido
                                            </mat-error>
                                            <mat-error *ngIf="emailConfirmControl.touched && emailConfirmControl.errors && emailConfirmControl.errors.pattern">El correo
                                              electrónico no es válido
                                            </mat-error>
                                            <mat-error *ngIf="emailConfirmControl.valid && emailControl.value !== emailConfirmControl.value">El
                                              correo electrónico no coincide</mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row center">
                                    <div class="form-holder form-holder-2">
                                        <button class="btnTomarFoto" type="button" (click)="nextStep1()" [disabled]="disabledNextContacto || loading">Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase=2> 
                            <div class="inner">
                                <h3 class="center" >
                                    <img src="assets/images/avion.jpg" class="center" style="height: 72px;width: 117px;" />
                                    <br/>Verificación de Correo Electrónico
                                </h3>
                                <div class="form-row center instrucciones" >
                                    <div class="form-holder form-holder-12">Introduce el codigo que enviamos a tu correo electrónico para verificar tu cuenta. <br/><br/> Debe estar en la bandeja de entrada de {{emailControl.value}} si no está ahi revisa la carpeta de spam </div>
                                    <br/><br/><br/><br/><br/>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-22"  style="text-align:center;">
                                        <input type="tel" name="codactcorreoTotal" mask="00000"  id="codactcorreoTotal" class="form-control" maxlength="5" required style="width:285px;letter-spacing:0.40em;text-align: center;">
                                    </div>
                                </div>
                                <div class="form-row center">
                                    <div class="form-holder form-holder-22">
                                        <p>Tiempo restante: {{minutesString}}:{{secondsString}} min</p>
                                    </div>
                                </div>
                                 <div class="form-row center">
                                    <div class="form-holder form-holder-22">
                                        <p><a class ='btn'><u (click)="recuperarCodigoCorreoRe()">Reenviar código</u></a></p>
                                    </div>
                                </div>
                                <div class="form-row center">
                                    <div class="form-holder form-holder-2">
                                        <button class="btnTomarFoto2" id="btn2_3a" type="button" (click)="backStep2()">Regresar</button>
                                    </div>
                                    <div class="form-holder form-holder-2">
                                        <button class="btnTomarFoto" id="btn2_3" type="button" (click)="nextStep2()">Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase=3>
                            <div class="inner" >
                                <h3 class="center" >
                                    <img src="assets/images/avion.jpg" class="center" style="height: 72px;width: 117px;" />
                                    <br/>Datos de Contacto
                                </h3>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2">Ingresa y confirma tu número de celular ya que enviaremos un código de verificación</div>
                                    <br/><br/><br/>
                                </div>
                                <div class="form-row center" style="display:none;" >
                                    <div class="form-holder form-holder-2">
                                        <p><img src="assets/images/telmovil.png" class="imgheight"  /></p>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Celular
                                            </mat-label>
                                            <input matInput type="tel" class="form-control" [formControl]="telefonoCodControl" mask="00-0000-0000" autocomplete="off" required (ngModelChange)="validaCamposTelefono()" appBlockCopyPaste>
                                        </mat-form-field>
                                         <div>
                                             <mat-error *ngIf="telefonoCodControl.touched && telefonoCodControl.errors && telefonoCodControl.errors.required">El campo teléfono
                                              es requerido
                                            </mat-error>
                                            <mat-error *ngIf="telefonoCodControl.touched && telefonoCodControl.errors && telefonoCodControl.errors.minlength">El campo teléfono celular debe
                                              tener exactamente 10 dígitos
                                            </mat-error>
                                            <mat-error *ngIf="telefonoCodControl.touched && telefonoCodControl.errors && telefonoCodControl.errors.onlyNumbers">El campo teléfono celular
                                              debe ser numérico
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Confirmar Celular
                                            </mat-label>
                                            <input matInput type="tel" class="form-control" [formControl]="telefonoConfirmControl" mask="00-0000-0000" autocomplete="off" required (ngModelChange)="validaCamposTelefono()" appBlockCopyPaste>
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="telefonoConfirmControl.touched && telefonoConfirmControl.errors && telefonoConfirmControl.errors.required">El campo teléfono
                                              es requerido
                                            </mat-error>
                                            <mat-error *ngIf="telefonoConfirmControl.touched && telefonoConfirmControl.errors && telefonoConfirmControl.errors.minlength">El campo teléfono celular debe
                                              tener exactamente 10 dígitos
                                            </mat-error>
                                            <mat-error *ngIf="telefonoConfirmControl.touched && telefonoConfirmControl.errors && telefonoConfirmControl.errors.onlyNumbers">El campo teléfono celular
                                              debe ser numérico
                                            </mat-error>
                                            <mat-error *ngIf="telefonoConfirmControl.valid && telefonoControl.value !== telefonoConfirmControl.value">El teléfono celular no coincide</mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row center">
                                    <div class="form-holder form-holder-2">
                                        <button class="btnTomarFoto2" type="button" (click)="backStep3()" [disabled]="disabledNextContacto || loading">Regresar</button>
                                    </div>
                                    <div class="form-holder form-holder-2">
                                        <button class="btnTomarFoto" type="button" (click)="nextStep3()" [disabled]="disabledNextContacto || loading">Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase=4>
                            <div class="inner">
                                <h3 class="center" >
                                    <img src="assets/images/avion.jpg" class="center" style="height: 72px;width: 117px;" />
                                    <br/>Verificación de Celular
                                    <br/><br/><br/>
                                </h3>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2">Captura el código que enviamos a tu celular: </div>
                                </div>
                                <div class="form-row center" style="display:none;">
                                    <div class="form-holder form-holder-2">Código de Activación</div>
                                </div>
                                 <div class="form-row center">
                                    <div class="form-holder form-holder-2">
                                        <p>Tiempo restante: {{minutesString}}:{{secondsString}} min</p>
                                    </div>
                                </div>
                                <div class="form-row center">
                                    <div class="form-holder form-holder-2">
                                        <input type="tel" inputmode="numeric" name="codacttelTotal" id="codacttelTotal" class="form-control" maxlength="6" required mask="000000" style="width:250px;letter-spacing:0.40em;text-align: center;">
                                    </div>
                                </div>
                                 <div class="form-row center">
                                    <div class="form-holder form-holder-2">
                                        <p><a class ='btn'><u (click)="recuperarCodigoSMSRe()">Reenviar código</u></a></p>
                                    </div>
                                </div>
                                <div class="form-row center">
                                    <div class="form-holder form-holder-2" >
                                        <button class="btnTomarFoto2" id="btn2_2a" type="button" (click)="backStep4()">Regresar</button>
                                    </div>
                                    <div class="form-holder form-holder-2" >
                                        <button class="btnTomarFoto" id="btn2_2" type="button" (click)="nextStep4()">Continuar</button>
                                    </div>
                                </div>       
                            </div>
                        </div>
                        <div *ngSwitchCase=5>
                            <div class="inner" >
                                <h3 class="center" >
                                    <img src="assets/images/avion.jpg" class="center" style="height: 72px;width: 117px;" />
                                    <br/>Datos personales del titular o del represantante legal 
                                </h3>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-12">Estos datos nos permiten tener un contacto más cercano a ti.</div>
                                    <br/><br/><br/>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2">
                                        Datos personales
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-5">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Primer Nombre
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="primerNombreRepControl" maxlength="40" required autocomplete="off" (ngModelChange)="validaDatosRep()" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="primerNombreRepControl.touched && primerNombreRepControl.errors && primerNombreRepControl.errors.required">El nombre es requerido</mat-error>
                                            <mat-error *ngIf="primerNombreRepControl.touched && primerNombreRepControl.errors && primerNombreRepControl.errors.onlyLetters">El nombre no debe tener
                                              caracteres especiales o números
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Segundo Nombre
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="segundoNombreRepControl" maxlength="40" autocomplete="off" (ngModelChange)="validaDatosRep()" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="segundoNombreRepControl.errors && segundoNombreRepControl.errors.onlyLetters">El segundo nombre no debe tener caracteres
                                            especiales o números</mat-error>
                                        </div>
                                    </div>
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Apellido Paterno
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="apellidoPaternoRepControl" maxlength="40" autocomplete="off" required (ngModelChange)="validaDatosRep()" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="apellidoPaternoRepControl.touched && apellidoPaternoRepControl.errors && apellidoPaternoRepControl.errors.required">El apellido paterno es requerido
                                            </mat-error>
                                            <mat-error *ngIf="apellidoPaternoRepControl.touched && apellidoPaternoRepControl.errors && apellidoPaternoRepControl.errors.onlyLetters">El apellido paterno no debe tener
                                            caracteres especiales o números</mat-error>
                                        </div>
                                    </div>
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Apellido Materno
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="apellidoMaternoRepControl" maxlength="40" autocomplete="off" required (ngModelChange)="validaDatosRep()" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="apellidoMaternoRepControl.touched && apellidoMaternoRepControl.errors && apellidoMaternoRepControl.errors.required">El apellido materno es requerido
                                            </mat-error>
                                            <mat-error *ngIf="apellidoMaternoRepControl.touched && apellidoMaternoRepControl.errors && apellidoMaternoRepControl.errors.onlyLetters">El apellido materno no debe tener
                                            caracteres especiales o números</mat-error>
                                        </div>
                                    </div>
                                </div>
                                 <div class="form-row">
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy"  class="my-form-field">
                                            <mat-label >
                                                Fecha Nacimiento
                                            </mat-label>
                                            <input matInput type="tel" mask="d0/M0/0000" [matDatepicker]="fechaNacimientoRep" inputmode="numeric" (dateInput)="fechaRep('input', $event)" autocomplete="off" required (ngModelChange)="validaDatosRep()" >
                                            <mat-datepicker-toggle matSuffix [for]="fechaNacimientoRep"></mat-datepicker-toggle>
                                            <mat-datepicker #fechaNacimientoRep></mat-datepicker>
                                        </mat-form-field>
                                        <mat-error *ngIf="error2">{{errorMensaje2}}</mat-error>
                                    </div>
                                     <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                RFC
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="rfcTitControl" maxlength="13" required autocomplete="off" (ngModelChange)="validaDatosRep()" (input)="forceUppercaseConditionally('rfcTitControl', $event);" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="rfcTitControl.touched && rfcTitControl.errors && rfcTitControl.errors.required">El RFC es requerido
                                            </mat-error>
                                            <mat-error *ngIf="rfcTitControl.touched && rfcTitControl.errors && rfcTitControl.errors.onlyLetters">El RFC es inválido</mat-error>
                                        </div>
                                    </div>
                                     <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                CURP
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="rfcCurpControl" maxlength="18" required autocomplete="off" (ngModelChange)="validaDatosRep()" (input)="forceUppercaseConditionally('rfcCurpControl', $event);" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="rfcCurpControl.touched && rfcCurpControl.errors && rfcCurpControl.errors.required">El CURP es requerido
                                            </mat-error>
                                            <mat-error *ngIf="rfcCurpControl.touched && rfcCurpControl.errors && rfcCurpControl.errors.onlyLetters">El CURP es inválido</mat-error>
                                        </div>
                                    </div>
                                     <div class="form-holder form-holder-5">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Celular
                                            </mat-label>
                                            <input matInput type="tel" class="form-control" [formControl]="telefonoControl" mask="00-0000-0000" autocomplete="off" required (ngModelChange)="validaCamposTelefono()" appBlockCopyPaste>
                                        </mat-form-field>
                                         <div>
                                             <mat-error *ngIf="telefonoControl.touched && telefonoControl.errors && telefonoControl.errors.required">El campo teléfono
                                              es requerido
                                            </mat-error>
                                            <mat-error *ngIf="telefonoControl.touched && telefonoControl.errors && telefonoControl.errors.minlength">El campo teléfono celular debe
                                              tener exactamente 10 dígitos
                                            </mat-error>
                                            <mat-error *ngIf="telefonoControl.touched && telefonoControl.errors && telefonoControl.errors.onlyNumbers">El campo teléfono celular
                                              debe ser numérico
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2">
                                        Dirección
                                    </div>
                                </div>
                                 <div class="form-row">
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Calle
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="direccionCalleRepControl" maxlength="40" autocomplete="off" (ngModelChange)="validaDatosRep()"  >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="direccionCalleRepControl.touched && direccionCalleRepControl.errors && direccionCalleRepControl.errors.required">Calle requerida</mat-error>
                                        </div>
                                    </div>
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Número
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="direccionNumeroRepControl" maxlength="40" autocomplete="off" (ngModelChange)="validaDatosRep()"  >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="direccionNumeroRepControl.touched && direccionNumeroRepControl.value === ''">Número requerido</mat-error>
                                            
                                        </div>
                                    </div>
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Código Postal
                                            </mat-label>
                                            <input matInput type="tel" class="form-control" [formControl]="direccionCPRepControl" maxlength="5" autocomplete="off" mask="00000" (change)="cpCapturado()">
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="direccionCPRepControl.touched && direccionCPRepControl.errors && direccionCPRepControl.errors.required">Código Postal requerido</mat-error>
                                            <mat-error *ngIf="direccionCPRepControl.touched && direccionCPRepControl.errors && direccionCPRepControl.errors.minLength">Código Postal debe ser de 5 dígitos</mat-error>
                                            <mat-error *ngIf="direccionCPRepControl.touched && direccionCPRepControl.errors && direccionCPRepControl.errors.onlyNumbers">El Código Postal debe tener solo números</mat-error>
                                        </div>
                                    </div>
                                     <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Colonia
                                              </mat-label>
                                              <mat-select [(value)]="coloniaRep" (valueChange)="coloniaTitSeleccionado()">
                                                <mat-option *ngFor="let account of colonias" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorColoniaRep">La colonia es requerida</mat-error>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                País
                                              </mat-label>
                                              <mat-select [(value)]="paisRep" (valueChange)="paisTitSeleccionado()">
                                                <mat-option *ngFor="let account of paises" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorPaisRep">El País es requerido</mat-error>
                                    </div>
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Entidad Federativa
                                              </mat-label>
                                              <mat-select [(value)]="entidadFederativaRep" (valueChange)="estadoTitSeleccionado()">
                                                <mat-option *ngFor="let account of entidadesFederativas" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorEntidadRep">La Entidad Federativa es requerida</mat-error>
                                    </div>
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Ciudad
                                            </mat-label>
                                            <mat-select [(value)]="ciudadRep" (valueChange)="ciudadTitSeleccionada()">
                                                <mat-option *ngFor="let account of ciudades" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorCiudadRep">La Ciudad es requerida</mat-error>
                                    </div>
                                </div>                     
                                <div class="form-row center">
                                    <div class="form-holder form-holder-2" >
                                        <button class="btnTomarFoto2" id="btn5a" type="button" (click)="backStep5()" [disabled]="loading">Regresar</button>
                                    </div>
                                    <div class="form-holder form-holder-2" >
                                        <button class="btnTomarFoto" id="btn5" type="button" (click)="nextStep5()" [disabled]="disabledNextRep || loading">Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase=6>
                            <div class="inner" >
                                <h3 class="center" >
                                    <img src="assets/images/avion.jpg" class="center" style="height: 72px;width: 117px;" />
                                    <br/>Datos del Comercio
                                </h3>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2">Ingresa la siguiente información sobre tu negocio:</div>
                                     <br/><br/><br/>
                               </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Nombre Comercial
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="nombreComercialControl" maxlength="120" required autocomplete="off" (ngModelChange)="validaDatosCom()" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="nombreComercialControl.touched && nombreComercialControl.errors && nombreComercialControl.errors.required">El nombre Comercial es requerido</mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Sitio Web
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="sitioWebControl" maxlength="250" autocomplete="off" (ngModelChange)="validaDatosCom()" >
                                        </mat-form-field>
                                        <div>
                                             <mat-error *ngIf="sitioWebControl.touched && sitioWebControl.errors && sitioWebControl.errors.pattern">El sitio Web es inválido
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Redes Sociales
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="redesSocialesControl" maxlength="250" autocomplete="off" (ngModelChange)="validaDatosCom()" >
                                        </mat-form-field>
                                        <div>
                                             <mat-error *ngIf="redesSocialesControl.touched && redesSocialesControl.errors && redesSocialesControl.errors.required">Las Redes Sociales son obligatorias
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Giro
                                              </mat-label>
                                              <mat-select [(value)]="giro" (valueChange)="validaDatosCom()">
                                                <mat-option *ngFor="let account of giros" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorGiro">El Giro es requerido</mat-error>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Moneda
                                              </mat-label>
                                              <mat-select [(value)]="moneda" (valueChange)="validaDatosCom()">
                                                <mat-option *ngFor="let account of monedas" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorMoneda">La Moneda es requerida</mat-error>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Tipo de Empresa
                                              </mat-label>
                                              <mat-select [(value)]="tipoEmpresa" (valueChange)="validaDatosCom()">
                                                <mat-option *ngFor="let account of tiposEmpresa" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorTipoEmpresa">El Tipo de Empresa es requerido</mat-error>
                                    </div>
                                </div>
                                <div class="form-row"  *ngIf="mostrarDireccion">
                                    <div class="form-holder form-holder-2 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Datos Fiscales igual a Datos Personales
                                              </mat-label>
                                              <mat-select [(value)]="opcionDireccionFis" (valueChange)="mostrarFis()">
                                                <mat-option *ngFor="let account of opcionesDireccion" [value]="account.ID">
                                                  {{ account.Name }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorTipoEmpresa">El Campo Dirección Fiscal es requerido</mat-error>
                                    </div>
                                </div>                     
                                <div class="form-row center">
                                    <div class="form-holder form-holder-2" >
                                        <button class="btnTomarFoto2" id="btn6a" type="button" (click)="backStep6()" [disabled]="loading">Regresar</button>
                                    </div>
                                    <div class="form-holder form-holder-2" >
                                        <button class="btnTomarFoto" id="btn6" type="button" (click)="nextStep6()" [disabled]="disabledNextCom || loading">Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase=7>
                            <div class="inner" >
                                <h3 class="center" >
                                    <img src="assets/images/avion.jpg" class="center" style="height: 72px;width: 117px;" />
                                    <br/>Datos Fiscales del Comercio
                                </h3>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2">Estos datos nos permiten contar con tu información fiscal.</div>
                                    <br/><br/><br/>
                                </div>
                                
                                <div class="form-row">
                                    <div class="form-holder form-holder-2">
                                        Datos fiscales
                                    </div>
                                </div>
                                <div class="form-row" *ngIf="mostrarNombre">
                                    <div class="form-holder form-holder-5">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Primer Nombre
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="primerNombreFisControl" maxlength="40" required autocomplete="off" (ngModelChange)="validaDatosFis()" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="primerNombreFisControl.touched && primerNombreFisControl.errors && primerNombreFisControl.errors.required">El nombre es requerido</mat-error>
                                            <mat-error *ngIf="primerNombreFisControl.touched && primerNombreFisControl.errors && primerNombreFisControl.errors.onlyLetters">El nombre no debe tener
                                              caracteres especiales o números
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Segundo Nombre
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="segundoNombreFisControl" maxlength="40" autocomplete="off" (ngModelChange)="validaDatosFis()" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="segundoNombreFisControl.errors && segundoNombreFisControl.errors.onlyLetters">El segundo nombre no debe tener caracteres
                                            especiales o números</mat-error>
                                        </div>
                                    </div>
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Apellido Paterno
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="apellidoPaternoFisControl" maxlength="40" autocomplete="off" required (ngModelChange)="validaDatosFis()" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="apellidoPaternoFisControl.touched && apellidoPaternoFisControl.errors && apellidoPaternoFisControl.errors.required">El apellido paterno es requerido
                                            </mat-error>
                                            <mat-error *ngIf="apellidoPaternoFisControl.touched && apellidoPaternoFisControl.errors && apellidoPaternoFisControl.errors.onlyLetters">El apellido paterno no debe tener
                                            caracteres especiales o números</mat-error>
                                        </div>
                                    </div>
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Apellido Materno
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="apellidoMaternoFisControl" maxlength="40" autocomplete="off" required (ngModelChange)="validaDatosFis()" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="apellidoMaternoFisControl.touched && apellidoMaternoFisControl.errors && apellidoMaternoFisControl.errors.required">El apellido materno es requerido
                                            </mat-error>
                                            <mat-error *ngIf="apellidoMaternoFisControl.touched && apellidoMaternoFisControl.errors && apellidoMaternoFisControl.errors.onlyLetters">El apellido materno no debe tener
                                            caracteres especiales o números</mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row" *ngIf="mostrarRazon">
                                    <div class="form-holder form-holder-2 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Razón Social
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="razonSocialFisControl" maxlength="120" autocomplete="off" required (ngModelChange)="validaDatosFis()" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="razonSocialFisControl.touched && razonSocialFisControl.errors && razonSocialFisControl.errors.required">La Razón Social es requerida
                                            </mat-error>
                                            <mat-error *ngIf="razonSocialFisControl.touched && razonSocialFisControl.errors && razonSocialFisControl.errors.onlyLetters">La Razón Social no debe tener
                                            caracteres especiales o números</mat-error>
                                        </div>
                                    </div>
                                </div>
                                 <div class="form-row">
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy"  class="my-form-field">
                                            <mat-label >
                                                Fecha de Alta
                                            </mat-label>
                                            <input matInput type="tel" mask="d0/M0/0000" [matDatepicker]="fechaAltaFis" inputmode="numeric" (dateInput)="fechaFis('input', $event)" autocomplete="off" required (ngModelChange)="validaDatosFis()" >
                                            <mat-datepicker-toggle matSuffix [for]="fechaAltaFis"></mat-datepicker-toggle>
                                            <mat-datepicker #fechaAltaFis></mat-datepicker>
                                        </mat-form-field>
                                        <mat-error *ngIf="error2">{{errorMensaje2}}</mat-error>
                                    </div>
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                RFC
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="rfcFisControl" maxlength="13" required autocomplete="off" (ngModelChange)="validaDatosFis()" (input)="forceUppercaseConditionally('rfcFisControl', $event);" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="rfcFisControl.touched && rfcFisControl.errors && rfcFisControl.errors.required">El RFC es requerido
                                            </mat-error>
                                            <mat-error *ngIf="rfcFisControl.touched && rfcFisControl.errors && rfcFisControl.errors.onlyLetters">El RFC es inválido</mat-error>
                                        </div>
                                    </div>
                                    <div class="form-holder form-holder-5 "  *ngIf="mostrarNombre">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                CURP
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="rfcCurpFisControl" maxlength="18" required autocomplete="off" (ngModelChange)="validaDatosFis()" (input)="forceUppercaseConditionally('rfcCurpFisControl', $event);" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="rfcCurpFisControl.touched && rfcCurpFisControl.errors && rfcCurpFisControl.errors.required">El CURP es requerido
                                            </mat-error>
                                            <mat-error *ngIf="rfcCurpFisControl.touched && rfcCurpFisControl.errors && rfcCurpFisControl.errors.onlyLetters">El CURP es inválido</mat-error>
                                        </div>
                                    </div>
                                     <div class="form-holder form-holder-5">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Teléfono
                                            </mat-label>
                                            <input matInput type="tel" class="form-control" [formControl]="telefonoFisControl" mask="00-0000-0000" autocomplete="off" required (ngModelChange)="validaDatosFis()" appBlockCopyPaste>
                                        </mat-form-field>
                                         <div>
                                             <mat-error *ngIf="telefonoFisControl.touched && telefonoFisControl.errors && telefonoFisControl.errors.required">El campo teléfono
                                              es requerido
                                            </mat-error>
                                            <mat-error *ngIf="telefonoFisControl.touched && telefonoFisControl.errors && telefonoFisControl.errors.minlength">El campo teléfono celular debe
                                              tener exactamente 10 dígitos
                                            </mat-error>
                                            <mat-error *ngIf="telefonoFisControl.touched && telefonoFisControl.errors && telefonoFisControl.errors.onlyNumbers">El campo teléfono celular
                                              debe ser numérico
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2">
                                        Dirección Fiscal
                                    </div>
                                </div>
                                 <div class="form-row">
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Calle
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="direccionCalleFisControl" maxlength="40" autocomplete="off" (ngModelChange)="validaDatosFis()"  >
                                        </mat-form-field>
                                        <div>   
                                            <mat-error *ngIf="direccionCalleFisControl.touched && direccionCalleFisControl.errors && direccionCalleFisControl.errors.required">Calle requerida</mat-error>
                                        </div>
                                    </div>
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Número
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="direccionNumeroFisControl" maxlength="40" autocomplete="off" (ngModelChange)="validaDatosFis()"  >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="direccionNumeroFisControl.touched && direccionNumeroFisControl.value === ''">Número requerido</mat-error>
                                            
                                        </div>
                                    </div>
                                     <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Código Postal
                                            </mat-label>
                                            <input matInput type="tel" class="form-control" [formControl]="direccionCPFisControl" maxlength="5" mask="00000" autocomplete="off" (change)="cpFisCapturado()">
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="direccionCPFisControl.touched && direccionCPFisControl.errors && direccionCPFisControl.errors.required">Código Postal requerido</mat-error>
                                            <mat-error *ngIf="direccionCPFisControl.touched && direccionCPFisControl.errors && direccionCPFisControl.errors.minlength">Código Postal debe ser de 5 dígitos</mat-error>
                                            <mat-error *ngIf="direccionCPFisControl.touched && direccionCPFisControl.errors && direccionCPFisControl.errors.onlyNumbers">El Código Postal debe tener solo números</mat-error>
                                        </div>
                                    </div>
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Colonia
                                              </mat-label>
                                              <mat-select [(value)]="coloniaFis" (valueChange)="coloniaFisSeleccionado()">
                                                <mat-option *ngFor="let account of coloniasFis" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorColoniaFis">La colonia es requerida</mat-error>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                País
                                              </mat-label>
                                              <mat-select [(value)]="paisFis" (valueChange)="paisFisSeleccionado()">
                                                <mat-option *ngFor="let account of paisesFis" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorPaisFis">El País es requerido</mat-error>
                                    </div>
                                    <div class="form-holder form-holder-5 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Entidad Federativa
                                              </mat-label>
                                              <mat-select [(value)]="entidadFederativaFis" (valueChange)="estadoFisSeleccionado()">
                                                <mat-option *ngFor="let account of entidadesFederativasFis" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorEntidadFis">La Entidad Federativa es requerida</mat-error>
                                    </div>
                                    <div class="form-holder form-holder-5 ">
                                         <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Ciudad
                                            </mat-label>
                                            <mat-select [(value)]="ciudadFis" (valueChange)="ciudadFisSeleccionada()">
                                                <mat-option *ngFor="let account of ciudadesFis" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorCiudadFis">La Ciudad es requerida</mat-error>
                                        
                                    </div>
                                </div>                     
                                <div class="form-row center">
                                    <div class="form-holder form-holder-2" >
                                        <button class="btnTomarFoto2" id="btn7a" type="button" (click)="backStep7()" [disabled]="loading">Regresar</button>
                                    </div>
                                    <div class="form-holder form-holder-2" >
                                        <button class="btnTomarFoto" id="btn7" type="button" (click)="nextStep7()" [disabled]="disabledNextRep2 || loading">Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase=8>
                            <div class="inner" >
                                <h3 class="center">Transaccionalidad y datos Bancarios</h3>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-12">Para darte la mejor comisión necesitamos saber información sobre tu transaccionalidad así como la información bancaria necesaria para transferirte tus ingresos.</div>
                                    <br/><br/><br/>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2">
                                        Datos de Transaccionalidad
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-50 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Ticket Promedio ($)
                                            </mat-label>
                                            <input matInput type="tel" class="form-control" [formControl]="ticketControl" maxlength="40" autocomplete="off" mask="separator.2" thousandSeparator="," required (ngModelChange)="validaDatosTrans()" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="ticketControl.touched && ticketControl.errors && ticketControl.errors.required">El Ticket Promedio es requerido
                                            </mat-error>
                                            <mat-error *ngIf="ticketControl.touched && ticketControl.errors && ticketControl.errors.onlyNumbers">El Ticket Promedio solo permite números
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="form-holder form-holder-50 ">
                                        &nbsp;
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-50 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Monto Diario de Ventas
                                            </mat-label>
                                            <mat-select [(value)]="montodiarioTrans" (valueChange)="validaDatosTrans()">
                                                <mat-option *ngFor="let account of montosdiarios" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorMontoDiario">El monto diario es requerido</mat-error>
                                    </div>
                                    <div class="form-holder form-holder-50 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Rango de Precio de los Productos
                                            </mat-label>
                                            <mat-select [(value)]="rangoPrecioTrans" (valueChange)="validaDatosTrans()">
                                                <mat-option *ngFor="let account of rangoprecios" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorRangoPrecio">Los Rangos de Precio son requeridos</mat-error>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-50 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Facturación Promedio Mensual
                                            </mat-label>
                                            <input matInput type="tel" class="form-control" [formControl]="facturacionControl" maxlength="40" autocomplete="off" mask="separator.2" thousandSeparator="," required (ngModelChange)="validaDatosTrans()" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="facturacionControl.touched && facturacionControl.errors && facturacionControl.errors.required">La Facturación Mensual Promedio es requerido
                                            </mat-error>
                                            <mat-error *ngIf="facturacionControl.touched && facturacionControl.errors && facturacionControl.errors.onlyNumbers">La Facturación Mensual Promedio solo permite números
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="form-holder form-holder-50 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Volúmen estimado (mensual)
                                            </mat-label>
                                            <mat-select [(value)]="volumenTrans" (valueChange)="validaDatosTrans()">
                                                <mat-option *ngFor="let account of volumenes" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorVolumen">El volumen estimado es requerido</mat-error>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-50 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Horarios de Comercio
                                            </mat-label>
                                            <input matInput class="form-control" [formControl]="horariosControl" maxlength="40" autocomplete="off" required (ngModelChange)="validaDatosTrans()" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="horariosControl.touched && horariosControl.errors && horariosControl.errors.required">Los horarios son requeridos
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="form-holder form-holder-50 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Residencia Clientes
                                            </mat-label>
                                            <mat-select [(value)]="paisCliente" multiple (valueChange)="validaDatosTrans()">
                                                <mat-option *ngFor="let account of paisesClientes" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorPaisRes">Los Países de Residencia son requeridos</mat-error>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2">
                                        Información Bancaria
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-50 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Banco
                                              </mat-label>
                                              <mat-select [(value)]="bancoTrans" (valueChange)="validaDatosTrans()">
                                                <mat-option *ngFor="let account of bancos" [value]="account.id">
                                                  {{ account.description }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorBancoTrans">El Banco es requerido</mat-error>
                                    </div>
                                    <div class="form-holder form-holder-50 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Meses Sin Intereses
                                              </mat-label>
                                              <mat-select [(value)]="mesesSinInteres" (valueChange)="validaDatosTrans()">
                                                <mat-option *ngFor="let account of opcionesDireccion" [value]="account.ID">
                                                  {{ account.Name }}</mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="errorMesSinInteres">Los meses sin intereses son requeridos</mat-error>
                                    </div>
                                     
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-50">
                                        <mat-form-field appearance="legacy">
                                            <mat-label>
                                                Cuenta
                                            </mat-label>
                                            <input matInput type="tel" class="form-control" [formControl]="cuentaControl" maxlength="11" required mask="00000000009" autocomplete="off" (ngModelChange)="validaDatosTrans()" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="cuentaControl.touched && cuentaControl.errors && cuentaControl.errors.required">La cuenta es requerida</mat-error>
                                            <mat-error *ngIf="cuentaControl.touched && cuentaControl.errors && cuentaControl.errors.onlyNumbers">La cuenta solo permite números</mat-error>
                                            <mat-error *ngIf="cuentaControl.touched && cuentaControl.errors && cuentaControl.errors.minlength">La cuenta debe ser de 11 caracteres</mat-error>
                                        </div>
                                    </div>
                                    <div class="form-holder form-holder-50 ">
                                        <mat-form-field appearance="legacy">
                                            <mat-label >
                                                Clabe
                                            </mat-label>
                                            <input matInput type="tel" class="form-control" [formControl]="clabeControl" maxlength="18" mask="000000000000000000" autocomplete="off" (ngModelChange)="validaDatosTrans()" >
                                        </mat-form-field>
                                        <div>
                                            <mat-error *ngIf="clabeControl.touched && clabeControl.errors && clabeControl.errors.required">La clabe es requerida</mat-error>
                                            <mat-error *ngIf="clabeControl.touched && clabeControl.errors && clabeControl.errors.onlyNumbers">La clabe solo permite números</mat-error>
                                            <mat-error *ngIf="clabeControl.touched && clabeControl.errors && clabeControl.errors.minlength">La clabe debe ser de 18 caracteres</mat-error>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                                <div class="form-row center">
                                    
                                    <div class="form-holder form-holder-2" >
                                        <button class="btnTomarFoto2" id="btn8a" type="button" (click)="backStep8()" [disabled]="loading">Regresar</button>
                                    </div>
                                    <div class="form-holder form-holder-2" >
                                        <button class="btnTomarFoto" id="btn8" type="button" (click)="nextStep8()" [disabled]="disabledNextTrans || loading">Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase=9>
                            <div class="inner" >
                                <h3 class="center" >
                                    <img src="assets/images/avion.jpg" class="center" style="height: 72px;width: 117px;" />
                                    <br/>Documentos
                                </h3>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-12">Para el correcto y seguro deposito de tus ingresos es necesario nos proporciones los siguientes documentos vigentes:</div>
                                    <br/><br/><br/>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2 ">
                                        <mat-label>
                                            <img src="assets/images/bullet_avioncito.png" style="height: 23px;width: 23px;"/>&nbsp;&nbsp;Identificación Oficial
                                        </mat-label>
                                    </div>
                                    <div class="form-holder form-holder-2 ">
                                        <button type="button" mat-raised-button (click)="fileInput.click()" style="background-color: #fff !important;border:none !important;"><img src="assets/images/Subir.png" style="height: 42px;width: 49px;"/></button>
                                        <input hidden (change)="onFileSelected($event)" #fileInput type="file">
                                        <span class="file-name">{{name}}</span>
                                    </div>
                                </div>
                                <div class="form-row">
                                     <div class="form-holder form-holder-2">
                                        <mat-label>
                                            <img src="assets/images/bullet_avioncito.png" style="height: 23px;width: 23px;"/>&nbsp;&nbsp;Comprobante de Domicilio 
                                        </mat-label>
                                         </div>
                                    <div class="form-holder form-holder-2">
                                        <button type="button" mat-raised-button (click)="fileInput2.click()" style="background-color: #fff !important;border:none !important;"><img src="assets/images/Subir.png" style="height: 42px;width: 49px;" /></button>
                                        <input hidden (change)="onFileSelected2($event)" #fileInput2 type="file">
                                        <span class="file-name">{{name2}}</span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2 ">
                                        <mat-label >
                                            <img src="assets/images/bullet_avioncito.png" style="height: 23px;width: 23px;"/>&nbsp;&nbsp;Estado de Cuenta
                                        </mat-label>
                                    </div>
                                    <div class="form-holder form-holder-2">
                                        <button type="button" mat-raised-button (click)="fileInput3.click()" style="background-color: #fff !important;border:none !important;"><img src="assets/images/Subir.png" style="height: 42px;width: 49px;"/></button>
                                        <input hidden (change)="onFileSelected3($event)" #fileInput3 type="file">
                                        <span class="file-name">{{name3}}</span>
                                    </div>
                                </div>
                                <div class="form-row"  *ngIf="this.tipoEmpresa != '1'">
                                    <div class="form-holder form-holder-2 ">
                                        <mat-label >
                                            <img src="assets/images/bullet_avioncito.png" style="height: 23px;width: 23px;"/>&nbsp;&nbsp;Acta Constitutiva
                                        </mat-label>
                                    </div>
                                    <div class="form-holder form-holder-2">
                                        <button type="button" mat-raised-button (click)="fileInput4.click()" style="background-color: #fff !important;border:none !important;"><img src="assets/images/Subir.png" style="height: 42px;width: 49px;"/> </button>
                                        <input hidden (change)="onFileSelected4($event)" #fileInput4 type="file">
                                        <span class="file-name">{{name4}}</span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2 ">
                                        <mat-label >
                                            <img src="assets/images/bullet_avioncito.png" style="height: 23px;width: 23px;"/>&nbsp;&nbsp;Constancia de Situación Fiscal
                                        </mat-label>
                                    </div>
                                    <div class="form-holder form-holder-2">
                                        <button type="button" mat-raised-button (click)="fileInput5.click()" style="background-color: #fff !important;border:none !important;"><img src="assets/images/Subir.png" style="height: 42px;width: 49px;"/></button>
                                        <input hidden (change)="onFileSelected5($event)" #fileInput5 type="file">
                                        <span class="file-name">{{name5}}</span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2 ">
                                            <mat-label >
                                                <img src="assets/images/bullet_avioncito.png" style="height: 23px;width: 23px;"/>&nbsp;&nbsp;Logo
                                            </mat-label>
                                        </div>
                                    <div class="form-holder form-holder-2">
                                        <button type="button" mat-raised-button (click)="fileInput6.click()" style="background-color: #fff !important;border:none !important;"><img src="assets/images/Subir.png" style="height: 42px;width: 49px;"/></button>
                                        <input hidden (change)="onFileSelected6($event)" #fileInput6 type="file">
                                        <span class="file-name">{{name6}}</span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2 ">
                                            <mat-label >
                                                <img src="assets/images/bullet_avioncito.png" style="height: 23px;width: 23px;"/>&nbsp;&nbsp;Foto Interior Negocio
                                            </mat-label>
                                        </div>
                                    <div class="form-holder form-holder-2">
                                        <button type="button" mat-raised-button (click)="fileInput7.click()" style="background-color: #fff !important;border:none !important;"><img src="assets/images/Subir.png" style="height: 42px;width: 49px;"/></button>
                                        <input hidden (change)="onFileSelected7($event)" #fileInput7 type="file">
                                        <span class="file-name">{{name7}}</span>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-holder form-holder-2 ">
                                            <mat-label >
                                                <img src="assets/images/bullet_avioncito.png" style="height: 23px;width: 23px;"/>&nbsp;&nbsp;Foto Exterior Negocio
                                            </mat-label>
                                        </div>
                                    <div class="form-holder form-holder-2">
                                        <button type="button" mat-raised-button (click)="fileInput8.click()" style="background-color: #fff !important;border:none !important;"><img src="assets/images/Subir.png" style="height: 42px;width: 49px;"/></button>
                                        <input hidden (change)="onFileSelected8($event)" #fileInput8 type="file">
                                        <span class="file-name">{{name8}}</span>
                                    </div>
                                </div>
                                <div class="form-row center">
                                    <div class="form-holder form-holder-2" >
                                        <button class="btnTomarFoto2_3" id="btn9a" type="button" (click)="backStep9()" [disabled]="loading">Regresar</button>
                                    </div>
                                    <div class="form-holder form-holder-2" >
                                        <button class="btnTomarFoto3_3" id="btn9b" type="button" (click)="saveStep9()" [disabled]="loading">Guardar</button>
                                    </div>
                                    <div class="form-holder form-holder-2" >
                                        <button class="btnTomarFoto_3" id="btn9" type="button" (click)="nextStep9()" [disabled]="disabledNextDoc || loading">Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase=10>
                            <div class="inner" >
                                <h3 class="center" >
                                    <img src="assets/images/CONFETTY.png" class="center" style="height: 72px;width: 117px;" />
                                    <br/>
                                    <img src="assets/images/avion.jpg" class="center" style="height: 72px;width: 117px;" />
                                    <br/>¡FELICIDADES!
                                </h3>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2 orange">¡Tu solicitud de contratación ha sido recibida!</div>
                                </div>
                                 <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2">Te enviamos a {{correoFinal}} correo electrónico con los siguientes pasos.</div>
                                </div>
                                <div class="form-row center" >
                                    <div class="form-holder form-holder-2 ">
                                        <p class="title-3">
                                            Tu Número de Seguimiento es:
                                        </p>
                                        <p class="title-4 orange">
                                            {{numeroSeguimientoFinal}}
                                        </p>
                                    </div>
                                </div>
                                <div class="form-row center">
                                    <div class="form-holder form-holder-2">
                                        <button class="btnTomarFoto" type="button">Aceptar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase=500>
                            <div class="inner" >
                                <h3 class="center" >
                                    <br/>
                                    <img src="assets/images/avion.jpg" class="center" style="height: 72px;width: 117px;" />
                                    <br/>¡TU CORREO TIENE UNA SOLICITUD INICIADA!
                                </h3>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2">&nbsp;</div>
                                </div>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2 orange">¿Deseas continuar con la solicitud pendiente o iniciar nuevamente la captura de información?</div>
                                </div>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2">&nbsp;</div>
                                </div>
                   
                                <div class="form-row center">
                                    <div class="form-holder form-holder-2">
                                        <button class="btnTomarFoto2" type="button" (click)="nextStep500(1)">Reiniciar</button>
                                    </div>
                                    <div class="form-holder form-holder-2">
                                        <button class="btnTomarFoto" type="button" (click)="nextStep500(0)">Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                     <div *ngSwitchCase=600>
                            <div class="inner" >
                                <h3 class="center" >
                                    <img src="assets/images/avion.jpg" class="center" style="height: 72px;width: 117px;" />
                                    <br/>¡GRACIAS POR TU REGISTRO!
                                </h3>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2 orange">¡Tu solicitud de contratación está en proceso de revisión!</div>
                                </div>
                                 <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2">Te enviamos a {{correoFinal}} un correo electrónico con los siguientes pasos.</div>
                                </div>
                                <div class="form-row center" >
                                    <div class="form-holder form-holder-2 ">
                                        <p class="title-3">
                                            Tu Número de Seguimiento es:
                                        </p>
                                        <p class="title-4 orange">
                                            {{numeroSeguimientoFinal}}
                                        </p>
                                    </div>
                                </div>
                                <div class="form-row center">
                                    <div class="form-holder form-holder-2">
                                        <button class="btnTomarFoto" type="button">Aceptar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase=700>
                            <div class="inner" >
                                <h3 class="center" >
                                    <img src="assets/images/CONFETTY.png" class="center" style="height: 72px;width: 117px;" />
                                    <br/>
                                    <img src="assets/images/avion.jpg" class="center" style="height: 72px;width: 117px;" />
                                    <br/>¡FELICIDADES!
                                </h3>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2 orange">¡Tu solicitud de contratación ha sido aprobada!</div>
                                </div>
                                 <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2">En breve te enviaremos a {{correoFinal}} un correo electrónico con los siguientes pasos <br/> para comenzar a recibir los beneficios de nuestra plataforma.</div>
                                </div>
                                <div class="form-row center" >
                                    <div class="form-holder form-holder-2 ">
                                        <p class="title-3">
                                            Tu Número de Seguimiento es:
                                        </p>
                                        <p class="title-4 orange">
                                            {{numeroSeguimientoFinal}}
                                        </p>
                                    </div>
                                </div>
                                <div class="form-row center">
                                    <div class="form-holder form-holder-2">
                                        <button class="btnTomarFoto" type="button">Aceptar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase=800>
                            <div class="inner" >
                                <h3 class="center" >
                                    <br/>
                                    <img src="assets/images/avion.jpg" class="center" style="height: 72px;width: 117px;" />
                                    <br/>¡ATENCIÓN!
                                </h3>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2 orange">¡Tu solicitud de contratación requiere correcciones!</div>
                                </div>
                                 <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2">Hemos revisado tu información y hemos detectado los siguiente:</div>
                                </div>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2"><br/></div>
                                </div>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2 redalert" >{{observacionesRechazo}}</div>
                                </div>
                                <div class="form-row center instrucciones">
                                    <div class="form-holder form-holder-2"><br/></div>
                                </div>
                                <div class="form-row center">
                                    <div class="form-holder form-holder-2">
                                        <button class="btnTomarFoto" type="button" (click)="nextStep800()">Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>