import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

@Injectable() 
export class PickDateAdapter extends NativeDateAdapter {
    parse(value: any): Date | null {
       if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
         const str = value.split('/');
         const year = Number(str[2]);
         const month = Number(str[1]) - 1;
         const date = Number(str[0]);
         return new Date(year, month, date);
       }
       const timestamp = typeof value === 'number' ? value : Date.parse(value);
       return isNaN(timestamp) ? null : new Date(timestamp);
     }
  format(date: Date, displayFormat: Object): string {
      if (displayFormat === 'input') {
          return formatDate(date,'dd/MM/yyyy',this.locale);;
      } else {
          return  formatDate(date,'MMM yyyy',this.locale);
      }
  }
}