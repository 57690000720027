import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {LoaderService} from "../services/loader.service";

@Component({
  selector: 'app-modal-loading',
  template: `
    <mat-card mat-dialog-content>
        <mat-card-content>
            <img src="assets/images/logoprincipal_azul.png" alt="" style="height:50px;">
            <p class="word">Procesando...</p>
            <mat-progress-bar mode="indeterminate" color="#A3CA00"></mat-progress-bar>
        </mat-card-content>
    </mat-card>
  `,
  styles: [
    '.mat-dialog-content { padding: 0 !important; margin: 0 !important; }',
    '.mat-card { border-radius: 0; position: relative; width: 100%; height: 100%; padding: 0 !important; }',
    '.mat-card-content { text-align: center; padding: 2rem 0 0 !important;}',
    '.mat-progress-bar { position: absolute; bottom: 0; left: 0; width: 100%; }'
  ],
})

export class ModalLoadingComponent implements OnInit {
  loading: boolean = false;

  constructor(private loaderService: LoaderService,public dialogRef: MatDialogRef<ModalLoadingComponent>) {

    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
      if (this.loading == false) {
          this.dialogRef.close();
      }
    });
  }
   
  ngOnInit(): void {
  }

}
