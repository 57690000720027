// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlIncode: 'https://saas-api.incodesmile.com',
  keyIncode: '4694dbe960a7263b7296b27027b8e5c0a8645100',
  confId: '6206e07b5af8cc0019b8c973',
  urlServicios: 'http://ec2-3-131-222-6.us-east-2.compute.amazonaws.com:8080/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 
  urlIncode: 'https://demo-api.incodesmile.com',
  keyIncode: 'fa84a76233ad268f25e1aec5685ac0d35bc44ebb',
  confId: '61007dfc39047e00157e6c68',
  urlNot: 'https://operacionesn4web.bancrea.com/OperacionesN4Web/',
  urlGen: 'https://operacionesn4web.bancrea.com/OperacionesN4Web/',
  urlAlta: 'https://operacionesn4web.bancrea.com/OperacionesN4Web/'
  
  urlIncode: 'https://saas-api.incodesmile.com',
  keyIncode: '4694dbe960a7263b7296b27027b8e5c0a8645100',
  confId: '6206e07b5af8cc0019b8c973',
  urlNot: 'https://operacionesctadigital.bancrea.com/OperacionesN4Web/',
  urlGen: 'https://operacionesctadigital.bancrea.com/OperacionesN4Web/',
  urlAlta: 'https://operacionesctadigital.bancrea.com/OperacionesN4Web/'
  
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
