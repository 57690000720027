import { Component, OnInit } from '@angular/core';
import { FormControl, Validators} from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CustomValidators } from './helpers/custom-validators';
import { timer } from 'rxjs';
import { MoveNextByMaxLengthDirective } from './helpers/move-next-bymax';
import { CuentaService } from './services/CuentaService.service';
import { select, Store } from '@ngrx/store';
import { LoaderService } from './services/loader.service';
import { environment } from '../environments/environment';
import { Observable, of } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators'; 

declare var OnBoarding: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    sdk: any;
    token: any;
    sesion: any;
    interviewID: any;
    disabledNextContacto = true;
    disabledNextRep = true;
    disabledNextRep2 = true;
    disabledNextTrans = true;
    disabledNextCom = true;
    disabledNextDoc = true;

    selectedFile: any = null;
    selectedFile2: any = null;
    selectedFile3: any = null;
    selectedFile4: any = null;
    selectedFile5: any = null;
    selectedFile6: any = null;
    selectedFile7: any = null;
    selectedFile8: any = null;

    name = '';
    name2 = '';
    name3 = '';
    name4 = '';
    name5 = '';
    name6 = '';
    name7 = '';
    name8 = '';
    
    source = timer(0, 1000);
    clock: any;

    minutes: any;
    minutesString: any;
    seconds: any; 
    secondsString: any;
    secondsFull: any;

    error = false;
    errorMensaje = "";
    error2 = false;
    errorMensaje2 = "";

    sesionIdVar = "";
    codigoCorreo = "";
    codigoSMS = "";
    observacionesRechazo = '';
    barLabel = "Indicador:";
    errores = false;
    erroresGenerales = "";
    warnings = false;
    warningsGenerales = "";
    successmsg = false;
    successmsgGenerales = "";
    aceptoTerminos = false;
    aceptoTerminosINE = false;
    desTerminos = true;
    desTerminosINE = true;

    mostrarNombre = false;
    mostrarRazon = false;
    mostrarDirFis = true;
    mostrarDireccion = false;

    errorFechaNacReq = false;
    errorEntidadRep = false;
    errorPaisRep = false;
    errorTipoEmpresa = false;
    errorMoneda = false;
    errorGiro = false;
    errorBancoTrans = false;
    errorFechaAltaReq = false;
    errorEntidadFis = false;
    errorPaisFis = false;
    errorCiudadFis = false;
    errorCiudadRep = false;
    errorVolumen = false;
    errorMesSinInteres = false;
    errorPaisRes = false;
    errorColoniaRep = false;
    errorColoniaFis = false;
    errorMontoDiario = false;
    errorRangoPrecio = false;
    
    loading = false;
    step = 100;//100;
    estatusRegistro = 0;
    
    telefonoControl = new FormControl('', Validators.compose([
      Validators.minLength(10),
      CustomValidators.patternValidator(/^[0-9]*$/, { onlyNumbers: true })
    ]));
    telefonoFisControl = new FormControl('', Validators.compose([
      Validators.minLength(10),
      CustomValidators.patternValidator(/^[0-9]*$/, { onlyNumbers: true })
    ]));
    telefonoCodControl = new FormControl('', Validators.compose([
      Validators.minLength(10),
      CustomValidators.patternValidator(/^[0-9]*$/, { onlyNumbers: true })
    ]));

    telefonoConfirmControl = new FormControl('', Validators.compose([
      Validators.minLength(10),
      CustomValidators.patternValidator(/^[0-9]*$/, { onlyNumbers: true })
    ]));

    emailConfirmControl = new FormControl('', Validators.compose([
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
    ]));
    emailControl = new FormControl('', Validators.compose([
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
    ]));

    primerNombreRepControl = new FormControl('', Validators.compose([
      Validators.required,
      CustomValidators.patternValidator(/^[a-zA-Z ]*$/, { onlyLetters: true })
    ]));

    segundoNombreRepControl = new FormControl('', Validators.compose([
      CustomValidators.patternValidator(/^[a-zA-Z ]*$/, { onlyLetters: true })
    ]));
    apellidoPaternoRepControl = new FormControl('', Validators.compose([
      Validators.required,
      CustomValidators.patternValidator(/^[a-zA-Z ]*$/, { onlyLetters: true })
    ]));
    apellidoMaternoRepControl = new FormControl('', Validators.compose([
      Validators.required,
      CustomValidators.patternValidator(/^[a-zA-Z ]*$/, { onlyLetters: true })
    ]));  

    direccionNumeroRepControl= new FormControl('', Validators.compose([
      Validators.required,
    ]));
    direccionCalleRepControl= new FormControl('', Validators.compose([
      Validators.required,
    ]));
    direccionColoniaRepControl = new FormControl('', Validators.compose([
      Validators.required,
    ]));
    direccionCPRepControl = new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
      CustomValidators.patternValidator(/^[0-9]*$/, { onlyNumbers: true })
    ]));

    razonSocialFisControl = new FormControl('', Validators.compose([
      Validators.required,
      CustomValidators.patternValidator(/^[a-zA-Z ]*$/, { onlyLetters: true })
    ]));
    rfcFisControl = new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(12),
      CustomValidators.patternValidator(/^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, { onlyLetters: true })
    ]));
    rfcTitControl = new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(12),
      CustomValidators.patternValidator(/^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, { onlyLetters: true })
    ]));
      rfcCurpControl = new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(12),
      CustomValidators.patternValidator(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/, { onlyLetters: true })
    ]));
    rfcCurpFisControl = new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(12),
      CustomValidators.patternValidator(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/, { onlyLetters: true })
    ]));
    primerNombreFisControl = new FormControl('', Validators.compose([
      Validators.required,
      CustomValidators.patternValidator(/^[a-zA-Z ]*$/, { onlyLetters: true })
    ]));

    segundoNombreFisControl = new FormControl('', Validators.compose([
      CustomValidators.patternValidator(/^[a-zA-Z ]*$/, { onlyLetters: true })
    ]));
    apellidoPaternoFisControl = new FormControl('', Validators.compose([
      Validators.required,
      CustomValidators.patternValidator(/^[a-zA-Z ]*$/, { onlyLetters: true })
    ]));
    apellidoMaternoFisControl = new FormControl('', Validators.compose([
      Validators.required,
      CustomValidators.patternValidator(/^[a-zA-Z ]*$/, { onlyLetters: true })
    ]));  

    direccionNumeroFisControl= new FormControl('', Validators.compose([
      Validators.required,
    ]));
    direccionCalleFisControl= new FormControl('', Validators.compose([
      Validators.required,
    ]));
    direccionColoniaFisControl = new FormControl('', Validators.compose([
      Validators.required,
    ]));
    direccionCPFisControl = new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
      CustomValidators.patternValidator(/^[0-9]*$/, { onlyNumbers: true })
    ]));

    nombreComercialControl= new FormControl('', Validators.compose([
      Validators.required,
    ]));

    sitioWebControl= new FormControl('', Validators.compose([
      Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
    ]));
    
    redesSocialesControl= new FormControl('', Validators.compose([
      Validators.required,
    ]));

    cuentaControl = new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(11),
      CustomValidators.patternValidator(/^[0-9]*$/, { onlyNumbers: true })
    ]));

    clabeControl= new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(18),
      Validators.maxLength(18),
      CustomValidators.patternValidator(/^[0-9]*$/, { onlyNumbers: true })
    ]));

    paisesTransControl = new FormControl('', Validators.compose([
      Validators.required,
    ]));

    horariosControl = new FormControl('', Validators.compose([
      Validators.required,
    ]));

    volumenControl= new FormControl('', Validators.compose([
      Validators.required,
    ]));

    facturacionControl= new FormControl('', Validators.compose([
      Validators.required,
    ]));

    ticketControl= new FormControl('', Validators.compose([
      Validators.required,
      CustomValidators.patternValidator(/^[0-9]*$/, { onlyNumbers: true })
    ]));

    myControl = new FormControl();
    //tipoBeneficiarios = [{ "ID" : 1, "Name" : "Persona Física" },{ "ID" : 2, "Name" : "Persona Moral" }];
    opcionesDireccion = [{ "ID" : 1, "Name" : "SI" },{ "ID" : 2, "Name" : "NO" }];

    entidadesFederativas: any; //  = [{ "CveEntidad" : 1, "Descripcion" : "Nuevo León" },{ "CveEntidad" : 2, "Descripcion" : "Estado de México" }]; // : any;
    entidadesFederativasFis: any; //  = [{ "CveEntidad" : 1, "Descripcion" : "Nuevo León" },{ "CveEntidad" : 2, "Descripcion" : "Estado de México" }]; // : any;

    paises: any; // = [{ "CvePais" : 1, "Descripcion" : "México" },{ "CvePais" : 2, "Descripcion" : "Estados Unidos" }]; //: any;
    paisesFis: any; // = [{ "CvePais" : 1, "Descripcion" : "México" },{ "CvePais" : 2, "Descripcion" : "Estados Unidos" }]; //: any;

    giros: any; // = [{ "CveGiro" : 1, "Descripcion" : "Giro 1" },{ "CveGiro" : 2, "Descripcion" : "Giro 2" }]; //: any;
    monedas: any; //= [{ "CveMoneda" : 1, "Descripcion" : "Pesos Mexicanos" },{ "CveMoneda" : 2, "Descripcion" : "Dólares" }]; //: any;
    bancos: any; //= [{ "CveBanco" : 1, "Descripcion" : "Banco 1" },{ "CveBanco" : 2, "Descripcion" : "Banco 2" }]; //: any;
    tiposEmpresa: any; //= [{ "CveEmpresa" : 1, "Descripcion" : "Persona Física con Actividad Empresarial" },{ "CveEmpresa" : 2, "Descripcion" : "Persona Moral" }]; //: any;
    ciudades: any; // = [{ "CveCiudad" : 1, "Descripcion" : "Monterrey" },{ "CveCiudad" : 2, "Descripcion" : "Apodaca" }]; //: any;
    ciudadesFis: any; // = [{ "CveCiudad" : 1, "Descripcion" : "Monterrey" },{ "CveCiudad" : 2, "Descripcion" : "Apodaca" }]; //: any;
    colonias: any; // = [{ "CveCiudad" : 1, "Descripcion" : "Monterrey" },{ "CveCiudad" : 2, "Descripcion" : "Apodaca" }]; //: any;
    coloniasFis: any; // = [{ "CveCiudad" : 1, "Descripcion" : "Monterrey" },{ "CveCiudad" : 2, "Descripcion" : "Apodaca" }]; //: any;
    montosdiarios: any;
    rangoprecios: any;
    
    volumenes: any; // = [{ "CveVolumen" : 1, "Descripcion" : "1-10" },{ "CveVolumen" : 2, "Descripcion" : "11-100" }]; //: any;
    paisesClientes: any; // = [{ "CvePais" : 1, "Descripcion" : "México" },{ "CvePais" : 2, "Descripcion" : "Estados Unidos" }]; //: any;

    fechaNacimiento: Date = new Date();
    fechaAlta: Date = new Date();

    idRegistro = '';
    entidadFederativaRep = '';
    paisRep = '';
    ciudadRep = '';
    tipoEmpresa = '';
    giro = '';
    moneda = '';
    entidadFederativaFis = '';
    paisFis = '';
    ciudadFis = '';
    bancoTrans = '';
    opcionDireccionFis = 2;
    mesesSinInteres = '';
    paisCliente = '';
    volumenTrans = '';
    coloniaRep = '';
    coloniaFis = '';
    montodiarioTrans = '';
    rangoPrecioTrans = '';abstract
    paso1estilo = '';
    paso1icono = '';
    paso2estilo = '';
    paso2icono = '';
    paso3estilo = '';
    paso3icono = '';
    paso4estilo = '';
    paso4icono = '';
    paso5estilo = '';
    paso5icono = '';

    numeroSeguimientoFinal = '';
    correoFinal = '';

    constructor(private cuentaservice: CuentaService, private store: Store, private loaderService: LoaderService) {
    }
  

    onFileSelected(event: any) {
      this.selectedFile = event.target.files[0] ?? null;
      
      this.name = '';
      if(this.selectedFile != null){
        let split = this.selectedFile?.name.split('.');
        let filename = split[0];
        let extension = split[1];
        if(filename.length > 10)
        {
            this.name = filename.substring(0, 10) + '.' + extension; 
        } else {
            this.name = this.selectedFile?.name; 
        }
      }
      
      this.validaDatosDoc();
    }
    onFileSelected2(event: any) {
      this.selectedFile2 = event.target.files[0] ?? null;
        
      this.name2 = '';
      if(this.selectedFile2 != null){
        let split = this.selectedFile2?.name.split('.');
        let filename = split[0];
        let extension = split[1];
        if(filename.length > 10)
        {
            this.name2 = filename.substring(0, 10) + '.' + extension; 
        } else {
            this.name2 = this.selectedFile2?.name; 
        }
      }
      
      this.validaDatosDoc();
    }
    onFileSelected3(event: any) {
      this.selectedFile3 = event.target.files[0] ?? null;
        
      this.name3 = '';
      if(this.selectedFile3 != null){
        let split = this.selectedFile3?.name.split('.');
        let filename = split[0];
        let extension = split[1];
        if(filename.length > 10)
        {
            this.name3 = filename.substring(0, 10) + '.' + extension; 
        } else {
            this.name3 = this.selectedFile3?.name; 
        }
      }
      
      this.validaDatosDoc();
    }
    onFileSelected4(event: any) {
      this.selectedFile4 = event.target.files[0] ?? null;
        
      this.name4 = '';
      if(this.selectedFile4 != null){
        let split = this.selectedFile4?.name.split('.');
        let filename = split[0];
        let extension = split[1];
        if(filename.length > 10)
        {
            this.name4 = filename.substring(0, 10) + '.' + extension; 
        } else {
            this.name4 = this.selectedFile4?.name; 
        }
      }
      
      this.validaDatosDoc();
    }
    onFileSelected5(event: any) {
        this.selectedFile5 = event.target.files[0] ?? null;
        
        this.name5 = '';
      if(this.selectedFile5 != null){
        let split = this.selectedFile5?.name.split('.');
        let filename = split[0];
        let extension = split[1];
        if(filename.length > 10)
        {
            this.name5 = filename.substring(0, 10) + '.' + extension; 
        } else {
            this.name5 = this.selectedFile5?.name; 
        }
      }
      
      this.validaDatosDoc();
    }
    onFileSelected6(event: any) {
      this.selectedFile6 = event.target.files[0] ?? null;
        
      this.name6 = '';
      if(this.selectedFile6 != null){
        let split = this.selectedFile6?.name.split('.');
        let filename = split[0];
        let extension = split[1];
        if(filename.length > 10)
        {
            this.name6 = filename.substring(0, 10) + '.' + extension; 
        } else {
            this.name6 = this.selectedFile6?.name; 
        }
      }
      
      this.validaDatosDoc();
    }
    onFileSelected7(event: any) {
      this.selectedFile7 = event.target.files[0] ?? null;
        
      this.name7 = '';
      if(this.selectedFile7 != null){
        let split = this.selectedFile7?.name.split('.');
        let filename = split[0];
        let extension = split[1];
        if(filename.length > 10)
        {
            this.name7 = filename.substring(0, 10) + '.' + extension; 
        } else {
            this.name7 = this.selectedFile7?.name; 
        }
      }
      
      this.validaDatosDoc();
    }
    onFileSelected8(event: any) {
      this.selectedFile8 = event.target.files[0] ?? null;
        
      this.name8 = '';
      if(this.selectedFile8 != null){
        let split = this.selectedFile8?.name.split('.');
        let filename = split[0];
        let extension = split[1];
        if(filename.length > 10)
        {
            this.name8 = filename.substring(0, 10) + '.' + extension; 
        } else {
            this.name8 = this.selectedFile8?.name; 
        }
      }
      
      this.validaDatosDoc();
    }
  
    async ngOnInit() {

      this.paso1estilo = 'current';
      this.paso1icono = 'zmdi-collection-item-1s';
      this.paso2estilo = '';
      this.paso2icono = 'zmdi-collection-item-2s';
      this.paso3estilo = '';
      this.paso3icono = 'zmdi-collection-item-3s';
      this.paso4estilo = '';
      this.paso4icono = 'zmdi-collection-item-4s';
      this.paso5estilo = '';
      this.paso5icono = 'zmdi-collection-item-5s';  

    }
  
  forceUppercaseConditionally(formControlName: any, event: any) {
      if(formControlName == 'rfcFisControl')
      {
          this.rfcFisControl.setValue(event.target.value.toUpperCase()); 
      }
      if(formControlName == 'rfcTitControl')
      {
          this.rfcTitControl.setValue(event.target.value.toUpperCase()); 
      }
      if(formControlName == 'rfcCurpControl')
      {
          this.rfcCurpControl.setValue(event.target.value.toUpperCase()); 
      }
      if(formControlName == 'rfcCurpFisControl')
      {
          this.rfcCurpFisControl.setValue(event.target.value.toUpperCase()); 
      }
 }
  
  validaCamposCorreo(){
    if(this.emailConfirmControl.valid && this.emailControl.valid)
    {
        if(this.emailControl.value === this.emailConfirmControl.value)
        {
            this.disabledNextContacto = false;
        }
        else
        {
          this.disabledNextContacto = true;
        }
    }
    else
    {
        this.disabledNextContacto = true;
    }
  }
  validaCamposTelefono(){
    if(this.telefonoConfirmControl.valid && this.telefonoControl.valid)
    {
        if(this.telefonoControl.value === this.telefonoConfirmControl.value)
        {
            this.disabledNextContacto = false;
        }
        else
        {
          this.disabledNextContacto = true;
        }
    }
    else
    {
        this.disabledNextContacto = true;
    }
  }
  
  valEntidadFedReq(){
    if(this.entidadFederativaRep == '')
    {
        this.errorEntidadRep = true;
    } else
    {
        this.errorEntidadRep = false;
    }
  }
  
  valPaisReq(){
    if(this.paisRep == '')
    {
        this.errorPaisRep = true;
    } else
    {
        this.errorPaisRep = false;
    }
  }
  
  
  valEntidadFedReq2(){
    if(this.entidadFederativaFis == '')
    {
        this.errorEntidadFis = true;
    } else
    {
        this.errorEntidadFis = false;
    }
  }
  
  validaDatosRep(){
    if(this.primerNombreRepControl.valid && this.segundoNombreRepControl.valid && this.apellidoPaternoRepControl.valid 
        && this.apellidoMaternoRepControl.valid && this.rfcTitControl.valid && this.rfcCurpControl.valid && this.telefonoControl.valid 
        && this.direccionCalleRepControl.valid && this.direccionNumeroRepControl.valid && this.direccionCPRepControl.valid 
        && this.coloniaRep != '' && this.paisRep != '' && this.entidadFederativaRep != '' && this.ciudadRep != '')
    {
        this.disabledNextRep = false;
    }
    else
    {
        this.disabledNextRep = true;
    }
  }
  
  validaDatosCom()
  {
    if(this.nombreComercialControl.valid && this.sitioWebControl.valid && this.redesSocialesControl.valid 
        && this.giro != '' && this.moneda != '' && this.tipoEmpresa != '' && this.opcionDireccionFis > 0)
    {
        this.disabledNextCom = false;
    }
    else
    {
        this.disabledNextCom = true;
    }
    
    if(this.tipoEmpresa == "1")
    {
        this.mostrarDireccion = true;
    }
    else
    {
        this.mostrarDireccion = false;
    }
  }
  
  validaDatosFis(){
    if(this.tipoEmpresa == "1")
    {
        if(this.primerNombreFisControl.valid && this.segundoNombreFisControl.valid && this.apellidoPaternoFisControl.valid 
        && this.apellidoMaternoFisControl.valid && this.rfcFisControl.valid && this.rfcCurpFisControl.valid && this.telefonoFisControl.valid 
        && this.direccionCalleFisControl.valid && this.direccionNumeroFisControl.valid && this.direccionCPFisControl.valid 
        && this.coloniaFis != '' && this.paisFis != '' && this.entidadFederativaFis != '' && this.ciudadFis != '')
        {
            this.disabledNextRep2 = false;
        }
        else
        {
            this.disabledNextRep2 = true;
        }
    }
    else
    {
        if(this.razonSocialFisControl.valid && this.rfcFisControl.valid && this.telefonoFisControl.valid 
        && this.direccionCalleFisControl.valid && this.direccionNumeroFisControl.valid && this.direccionCPFisControl.valid 
        && this.coloniaFis != '' && this.paisFis != '' && this.entidadFederativaFis != '' && this.ciudadFis != '')
        {
            this.disabledNextRep2 = false;
        }
        else
        {
            this.disabledNextRep2 = true;
        }
    }
  }
  
  validaDatosTrans(){
    this.errorVolumen = false;
    this.errorRangoPrecio = false;
    this.errorMontoDiario = false;
    this.errorMesSinInteres = false;
    this.errorPaisRes = false;
    this.errorBancoTrans = false;
    
    if(this.horariosControl.valid && this.ticketControl.valid && this.cuentaControl.valid 
        && this.clabeControl.valid 
        && this.volumenTrans != '' && this.paisCliente != '' && this.bancoTrans != '' 
        && this.mesesSinInteres != '' && this.rangoPrecioTrans != '' && this.montodiarioTrans != '')
    {
        this.disabledNextTrans = false;
        this.errorVolumen = false;
        this.errorRangoPrecio = false;
        this.errorMontoDiario = false;
        this.errorMesSinInteres = false;
        this.errorPaisRes = false;
        this.errorBancoTrans = false;
    }
    else
    {
        this.disabledNextTrans = true;
        if(this.volumenTrans == '')
        {
           this.errorVolumen = true;
        }
        if(this.rangoPrecioTrans == '')
        {
           this.errorRangoPrecio = true;
        }
        if(this.montodiarioTrans == '')
        {
           this.errorMontoDiario = true;
        }
        if(this.paisCliente == '')
        {
           this.errorPaisRes = true;
        }
        if(this.bancoTrans == '')
        {
           this.errorBancoTrans = true;
        }
        if(this.mesesSinInteres == '')
        {
           this.errorMesSinInteres = true;
        }
    }
  }
  
  validaDatosDoc(){
    if(this.selectedFile != null && this.selectedFile2 != null && this.selectedFile3 != null &&
        this.selectedFile5 != null && this.selectedFile6 != null &&
        this.selectedFile7 != null && this.selectedFile8 != null)
    {
        this.disabledNextDoc = false;
    }
    else
    {
        if(this.tipoEmpresa != "1")
        {
            if(this.selectedFile4 != null)
            {
                this.disabledNextDoc = false;
            }
            else
            {
                this.disabledNextDoc = true;
            }
        }
        else
        {
            this.disabledNextDoc = true;
        }
    }
  }
  
  
  mostrarFis(){
    if(this.opcionDireccionFis == 2)
    {
        this.mostrarDirFis = true;
    }
    else
    {
        this.mostrarDirFis = false;
        
        this.primerNombreFisControl.setValue(this.primerNombreRepControl.value);
        this.segundoNombreFisControl.setValue(this.segundoNombreRepControl.value);
        this.apellidoPaternoFisControl.setValue(this.apellidoPaternoRepControl.value);
        this.apellidoMaternoFisControl.setValue(this.apellidoMaternoRepControl.value);
        
        this.fechaAlta = this.fechaNacimiento;
                
        this.rfcFisControl.setValue(this.rfcTitControl.value);
        this.rfcCurpFisControl.setValue(this.rfcCurpControl.value);
        this.telefonoFisControl.setValue(this.telefonoControl.value);
        
        this.direccionCalleFisControl.setValue(this.direccionCalleRepControl.value);
        this.direccionNumeroFisControl.setValue(this.direccionNumeroRepControl.value);
        this.direccionCPFisControl.setValue(this.direccionCPRepControl.value);
        
        this.cpFisCapturadoInterno(this.coloniaRep);      
    }
    
    this.validaDatosCom();
  }
  
  fechaRep(type: string, event: MatDatepickerInputEvent<Date>) {
    
    var fechaActual: Date = new Date();
    fechaActual.setFullYear(fechaActual.getFullYear() -18);
    
    this.errorMensaje = "";
    if (event.value !== null) {
      this.fechaNacimiento = event.value;
      this.errorFechaNacReq = false;
    } else {
       this.errorFechaNacReq = true; 
    }
    
    if (this.fechaNacimiento.getTime() <= fechaActual.getTime()) {
      this.error = false;
    } else {
      this.error = true;
      this.errorMensaje = 'Fecha inválida. Debe ser mayor de edad.';
    }
  }
  
  fechaFis(type: string, event: MatDatepickerInputEvent<Date>) {
    var fechaActual: Date = new Date();
    fechaActual.setFullYear(fechaActual.getFullYear() -18);
    
    this.errorMensaje2 = "";
    if (event.value !== null) {
      this.fechaAlta = event.value;
    }
  }
  
  nextInicializar = () => {
    this.paso1estilo = 'current';
    this.paso1icono = 'zmdi-collection-item-1s';
    this.paso2estilo = '';
    this.paso2icono = 'zmdi-collection-item-2s';
    this.paso3estilo = '';
    this.paso3icono = 'zmdi-collection-item-3s';
    this.paso4estilo = '';
    this.paso4icono = 'zmdi-collection-item-4s';
    this.paso5estilo = '';
    this.paso5icono = 'zmdi-collection-item-5s';
    this.errores = false;
    this.erroresGenerales = "";
    this.step = 0;
  }
  
  aceptarTerminos(){
    if(this.aceptoTerminos)
    {
        this.desTerminos = false;
    }
    else
    {
        this.desTerminos = true;
    }
  }
  
  
  
  nextStep100 = () => {
      this.errores = false;
      this.erroresGenerales = "";
      this.step = 1;
      
      this.getDatosCombos();
        
  }
  
  nextStep800 = () => {
      this.errores = false;
      this.erroresGenerales = "";
      this.warnings = true;
      this.warningsGenerales = this.observacionesRechazo;
      
      this.step = 5;
  }
  
  nextStep0 = () => {
      this.errores = false;
      this.erroresGenerales = "";
      this.step = 1;
  }
  
  nextStep1 = () => {
    this.errores = false;
    this.erroresGenerales = "";
    
    this.minutesString =  "";
    this.secondsString = "";

    this.recuperarCodigoCorreo();
  }
  
  backStep2 = () => {
    this.step = 1;
  }
  
  nextStep2 = () => {
    this.errores = false;
    this.erroresGenerales = "";

    var cod1 = (<HTMLInputElement>document.getElementById('codactcorreoTotal')).value;
    
    if(cod1 != '')
    {
        if( this.secondsFull > 0)
        {
            var codigo = cod1;
            if(codigo == this.codigoCorreo)
            {
                clearInterval(this.clock);
                
                if(this.estatusRegistro == 0)
                {
                    this.saveCodigo(1);
                } 
                if(this.estatusRegistro == 1)
                {
                    this.step = 500;
                }
                
                if(this.estatusRegistro > 1)
                {
                    this.saveCodigo(0);
                }
            }
            else
            {
                this.erroresGenerales = "Código incorrecto.";
                this.errores = true;
            }
        }
        else
        {
            this.erroresGenerales = "Código inválido. Solicite uno nuevo";
            this.errores = true;
        }
    }
    else
    {
        this.erroresGenerales = "Capture un código.";
        this.errores = true;
    }
  }
  
  nextStep500(ireinicio)
  {
      this.saveCodigo(ireinicio);
  }
  
  backStep3 = () => {
    this.step = 2;
  } 
  nextStep3 = () => {
    this.errores = false;
    this.erroresGenerales = "";
    
    this.minutesString =  "";
    this.secondsString = "";

    this.secondsFull = 0;
    this.recuperarCodigoSMS();
    
    /*this.paso1estilo = 'done';
    this.paso1icono = 'zmdi-collection-item-check';
    this.paso2estilo = 'current';
    this.paso2icono = 'zmdi-collection-item-2s';
    this.paso3estilo = '';
    this.paso3icono = 'zmdi-collection-item-3s';
    this.paso4estilo = '';
    this.paso4icono = 'zmdi-collection-item-4s';
    this.paso5estilo = '';
    this.paso5icono = 'zmdi-collection-item-5s';*/

    //this.step = 4;
    //this.saveStep('3');
  }
  
  
  backStep4 = () => {
    this.step = 3;
  } 
  nextStep4 = () => {
    this.errores = false;
    this.erroresGenerales = "";

    var cod1 = (<HTMLInputElement>document.getElementById('codacttelTotal')).value;
    
    if(cod1 != '')
    {
        if( this.secondsFull > 0)
        {
            var codigo = cod1;
            if(codigo == this.codigoSMS)
            {
                //this.clock.unsubscribe();
                clearInterval(this.clock);
                this.secondsFull = 0;
                
                this.paso1estilo = 'done';
                this.paso1icono = 'zmdi-collection-item-1check';
                this.paso2estilo = 'current';
                this.paso2icono = 'zmdi-collection-item-2s';
                this.paso3estilo = '';
                this.paso3icono = 'zmdi-collection-item-3s';
                this.paso4estilo = '';
                this.paso4icono = 'zmdi-collection-item-4s';
                this.paso5estilo = '';
                this.paso5icono = 'zmdi-collection-item-5s';
                
                this.step = 5;
                
                //this.inicializarSDK();
            }
            else
            {
                this.erroresGenerales = "Código incorrecto.";
                this.errores = true;
            }
        }
        else
        {
            this.erroresGenerales = "Código inválido. Solicite uno nuevo";
            this.errores = true;
        }
    }
    else
    {
        this.erroresGenerales = "Capture un código.";
        this.errores = true;
    }
  }
  
  
  backStep5 = () => {
    this.step = 1;
  } 
  nextStep5 = () => {
        this.saveStep1();
  }
  
  
  backStep6 = () => {
    this.step = 5;
  } 
  nextStep6 = () => {
      this.saveStep2();
  }
  
  
  backStep7 = () => {
    this.step = 6;
  } 
  nextStep7 = () => {
       this.saveStep3();
  }
  
  
  backStep8 = () => {
    this.step = 7;
  } 
  nextStep8 = () => {
    this.saveStep4();
  }
  
  
  backStep9 = () => {
    this.step = 8;
  } 
  nextStep9 = () => {
      this.saveStep5();
  }
  
  saveStep9 = () => {
      this.saveStep5a();
  }
  
  
  recuperarCodigoCorreoRe(){
    (<HTMLInputElement>document.getElementById('codactcorreoTotal')).value = '';
      
    this.recuperarCodigoCorreo();
  }
    
  recuperarCodigoCorreo(){
    
    let data = {
      email: this.emailControl.value,
    };  
    
    this.loading = true;
    this.loaderService.isLoading.next(true);
    this.errores = false;
    this.erroresGenerales = "";
    
    this.cuentaservice.getCodigo(data).subscribe((rs: any) => {
       
       if(rs.result)
       {
            this.codigoCorreo = rs.description;
            this.secondsFull = rs.minutos; 
            this.estatusRegistro = rs.estatus;
            this.correoFinal = rs.correoElectronico;
            this.numeroSeguimientoFinal = rs.folio;
                            
            clearInterval(this.clock);

            this.clock = setInterval(() => {
                this.secondsFull  = this.secondsFull - 1;

                if(this.secondsFull >= 0)
                {
                    this.minutes = Math.floor(this.secondsFull / 60);
                    this.seconds = this.secondsFull - (this.minutes * 60);

                    this.minutesString =  this.minutes.toString().padStart(2, '0');
                    this.secondsString =  this.seconds.toString().padStart(2, '0');
                }

                if(this.secondsFull <= 0)
                {
                    clearInterval(this.clock);
                }
            },1000);

            this.step = 2;
            
            this.loading = false;
            this.loaderService.isLoading.next(false);

       } else {
            this.erroresGenerales = rs.message;
            this.errores = true;
            this.loading = false;
            this.loaderService.isLoading.next(false);
       }
       
       //this.saveStep('1');
    }, err => {
            this.erroresGenerales = "Error al generar el código";
            this.errores = true;
            this.loading = false;
            this.loaderService.isLoading.next(false);
        }
    );
  }
  
 
  
  getDatosCombos(){
    this.loading = true;
    this.loaderService.isLoading.next(true);
    
    this.cuentaservice.getBancos().subscribe((rs: any) => {
        this.bancos = rs.items;
        this.cuentaservice.getGiros().subscribe((rs: any) => {
                this.giros = rs.items;
                this.cuentaservice.getMonedas().subscribe((rs: any) => {
                        this.monedas = rs.items;
                        this.cuentaservice.getPaises().subscribe((rs: any) => {
                                this.paises = rs.items;
                                this.paisesFis = rs.items;
                                this.cuentaservice.getResidencias().subscribe((rs: any) => {
                                        this.paisesClientes = rs.items;
                                        this.cuentaservice.getTiposEmpresa().subscribe((rs: any) => {
                                                this.tiposEmpresa = rs.items;
                                                this.cuentaservice.getVolumenes().subscribe((rs: any) => {
                                                        this.volumenes = rs.items;
                                                        this.cuentaservice.getMontosDiarios().subscribe((rs: any) => {
                                                                this.montosdiarios = rs.items;
                                                                this.cuentaservice.getRangoPrecios().subscribe((rs: any) => {
                                                                        this.rangoprecios = rs.items;
                                                                        this.errores = false;
                                                                        this.erroresGenerales = "";
                                                                        this.loading = false;
                                                                        this.loaderService.isLoading.next(false);
                                                                    }, err => {
                                                                    this.errores = true;
                                                                    this.erroresGenerales = "(T) Error al obtener datos a usar en el flujo";
                                                                    this.loading = false;
                                                                    this.loaderService.isLoading.next(false);
                                                                });
                                                            }, err => {
                                                            this.errores = true;
                                                            this.erroresGenerales = "(T) Error al obtener datos a usar en el flujo";
                                                            this.loading = false;
                                                            this.loaderService.isLoading.next(false);
                                                        });
                                                    }, err => {
                                                    this.errores = true;
                                                    this.erroresGenerales = "(T) Error al obtener datos a usar en el flujo";
                                                    this.loading = false;
                                                    this.loaderService.isLoading.next(false);
                                                });
                                            }, err => {
                                            this.errores = true;
                                            this.erroresGenerales = "(T) Error al obtener datos a usar en el flujo";
                                            this.loading = false;
                                            this.loaderService.isLoading.next(false);
                                        });
                                    }, err => {
                                    this.errores = true;
                                    this.erroresGenerales = "(T) Error al obtener datos a usar en el flujo";
                                    this.loading = false;
                                    this.loaderService.isLoading.next(false);
                                });
                            }, err => {
                            this.errores = true;
                            this.erroresGenerales = "(P) Error al obtener datos a usar en el flujo";
                            this.loading = false;
                            this.loaderService.isLoading.next(false);
                        });
                    }, err => {
                    this.errores = true;
                    this.erroresGenerales = "(N) Error al obtener datos a usar en el flujo";
                    this.loading = false;
                    this.loaderService.isLoading.next(false);
                });
            }, err => {
                this.errores = true;
                this.erroresGenerales = "(G) Error al obtener datos a usar en el flujo";
                this.loading = false;
                this.loaderService.isLoading.next(false);
            });
        }, err => {
            this.errores = true;
            this.erroresGenerales = "(E) Error al obtener datos a usar en el flujo";
            this.loading = false;
            this.loaderService.isLoading.next(false);
        });     
  }  
  
  paisTitSeleccionado(){
    
    this.loading = true;
    this.loaderService.isLoading.next(true);
    
    this.cuentaservice.getEstadosPorPais(this.paisRep).subscribe((rs: any) => {
            this.entidadesFederativas = rs.items;

            this.direccionCPRepControl.setValue('');
            this.colonias = [];
            this.coloniaRep = '';

            this.validaDatosRep();
            
            this.errores = false;
            this.erroresGenerales = "";
            this.loading = false;
            this.loaderService.isLoading.next(false);
        }, err => {
        this.errores = true;
        this.erroresGenerales = "(T) Error al obtener datos de estados";
        this.loading = false;
        this.loaderService.isLoading.next(false);
    });
  }
  
  
  estadoTitSeleccionado(){
    this.loading = true;
    this.loaderService.isLoading.next(true);
    
    this.cuentaservice.getCiudades(this.entidadFederativaRep).subscribe((rs: any) => {
            this.ciudades = rs.items;
            
            this.direccionCPRepControl.setValue('');
            this.colonias = [];
            this.coloniaRep = '';

            this.validaDatosRep();
            
            this.errores = false;
            this.erroresGenerales = "";
            this.loading = false;
            this.loaderService.isLoading.next(false);
        }, err => {
        this.errores = true;
        this.erroresGenerales = "(T) Error al obtener datos de ciudades";
        this.loading = false;
        this.loaderService.isLoading.next(false);
    });
  }
  
  ciudadTitSeleccionada(){
        this.direccionCPRepControl.setValue('');
        this.colonias = [];
        this.coloniaRep = '';
        this.validaDatosRep();

  }
  
  cpCapturado() {
    if(this.direccionCPRepControl.valid){
        
        this.loading = true;
        this.loaderService.isLoading.next(true);

        this.cuentaservice.getSepomex(this.direccionCPRepControl.value).subscribe((rs: any) => {
                this.colonias = rs.items;
                this.validaDatosRep();

                this.errores = false;
                this.erroresGenerales = "";
                this.loading = false;
                this.loaderService.isLoading.next(false);
            }, err => {
            this.errores = true;
            this.erroresGenerales = "(T) Error al obtener datos de ciudades";
            this.loading = false;
            this.loaderService.isLoading.next(false);
        });
    }  
  }
  
  cpCapturadoInterno(value : any) {
    if(this.direccionCPRepControl.valid){
        
        this.loading = true;
        this.loaderService.isLoading.next(true);

        this.cuentaservice.getSepomex(this.direccionCPRepControl.value).subscribe((rs: any) => {
                this.colonias = rs.items;
                this.coloniaRep = value;
                //this.validaDatosRep();

                this.errores = false;
                this.erroresGenerales = "";
                this.loading = false;
                this.loaderService.isLoading.next(false);
                
                this.coloniaTitSeleccionado();
                
            }, err => {
            this.errores = true;
            this.erroresGenerales = "(T) Error al obtener datos de ciudades";
            this.loading = false;
            this.loaderService.isLoading.next(false);
        });
    }  
  }
  
  coloniaTitSeleccionado(){
    
    let element = this.colonias.filter(colonia => colonia.id == this.coloniaRep);
    
    this.paisRep = element[0].idPais;
    this.loading = true;
    this.loaderService.isLoading.next(true);
    
    this.cuentaservice.getEstadosPorPais(this.paisRep).subscribe((rs: any) => {
            this.entidadesFederativas = rs.items;
            this.entidadFederativaRep = element[0].idEstado;
            this.cuentaservice.getCiudades(this.entidadFederativaRep).subscribe((rs: any) => {
                 this.ciudades = rs.items;
                 this.ciudadRep = element[0].idCiudad;
                 
                 this.validaDatosRep();

                 this.errores = false;
                 this.erroresGenerales = "";
                 this.loading = false;
                 this.loaderService.isLoading.next(false);
             }, err => {
             this.errores = true;
             this.erroresGenerales = "(T) Error al obtener datos de ciudades";
             this.loading = false;
             this.loaderService.isLoading.next(false);
         });
            
            this.errores = false;
            this.erroresGenerales = "";
            this.loading = false;
            this.loaderService.isLoading.next(false);
        }, err => {
        this.errores = true;
        this.erroresGenerales = "(T) Error al obtener datos de estados";
        this.loading = false;
        this.loaderService.isLoading.next(false);
    });
  }
  
  paisFisSeleccionado(){
    
    this.loading = true;
    this.loaderService.isLoading.next(true);
    
    this.cuentaservice.getEstadosPorPais(this.paisFis).subscribe((rs: any) => {
            this.entidadesFederativasFis = rs.items;

            this.direccionCPFisControl.setValue('');
            this.coloniasFis = [];
            this.coloniaFis = '';

            this.validaDatosFis();
            
            this.errores = false;
            this.erroresGenerales = "";
            this.loading = false;
            this.loaderService.isLoading.next(false);
        }, err => {
        this.errores = true;
        this.erroresGenerales = "(T) Error al obtener datos de estados";
        this.loading = false;
        this.loaderService.isLoading.next(false);
    });
  }
  
  
  estadoFisSeleccionado(){
    this.loading = true;
    this.loaderService.isLoading.next(true);
    
    this.cuentaservice.getCiudades(this.entidadFederativaFis).subscribe((rs: any) => {
            this.ciudadesFis = rs.items;
            
            this.direccionCPFisControl.setValue('');
            this.coloniasFis = [];
            this.coloniaFis = '';

            this.validaDatosFis();
            
            this.errores = false;
            this.erroresGenerales = "";
            this.loading = false;
            this.loaderService.isLoading.next(false);
        }, err => {
        this.errores = true;
        this.erroresGenerales = "(T) Error al obtener datos de ciudades";
        this.loading = false;
        this.loaderService.isLoading.next(false);
    });
  }
  
  ciudadFisSeleccionada(){
        this.direccionCPFisControl.setValue('');
        this.coloniasFis = [];
        this.coloniaFis = '';
        this.validaDatosFis();

  }
  
  cpFisCapturado() {
    if(this.direccionCPFisControl.valid){
        this.loading = true;
        this.loaderService.isLoading.next(true);

        this.cuentaservice.getSepomex(this.direccionCPFisControl.value).subscribe((rs: any) => {
                this.coloniasFis = rs.items;
                this.validaDatosFis();

                this.errores = false;
                this.erroresGenerales = "";
                this.loading = false;
                this.loaderService.isLoading.next(false);
            }, err => {
            this.errores = true;
            this.erroresGenerales = "(T) Error al obtener datos de ciudades";
            this.loading = false;
            this.loaderService.isLoading.next(false);
        });
    }  
  }
  
  cpFisCapturadoInterno(value: any) {
    if(this.direccionCPFisControl.valid){
        this.loading = true;
        this.loaderService.isLoading.next(true);

        this.cuentaservice.getSepomex(this.direccionCPFisControl.value).subscribe((rs: any) => {
                this.coloniasFis = rs.items;
                this.coloniaFis = value;
    
                this.errores = false;
                this.erroresGenerales = "";
                this.loading = false;
                this.loaderService.isLoading.next(false);
                
                this.coloniaFisSeleccionado();
                
            }, err => {
            this.errores = true;
            this.erroresGenerales = "(T) Error al obtener datos de ciudades";
            this.loading = false;
            this.loaderService.isLoading.next(false);
        });
    }  
  }
  
  coloniaFisSeleccionado(){
    
    let element = this.coloniasFis.filter(colonia => colonia.id == this.coloniaFis);
    
    this.paisFis = element[0].idPais;
    this.loading = true;
    this.loaderService.isLoading.next(true);
    
    this.cuentaservice.getEstadosPorPais(this.paisFis).subscribe((rs: any) => {
            this.entidadesFederativasFis = rs.items;
            this.entidadFederativaFis = element[0].idEstado;
            this.cuentaservice.getCiudades(this.entidadFederativaFis).subscribe((rs: any) => {
                 this.ciudadesFis = rs.items;
                 this.ciudadFis = element[0].idCiudad;
                 
                 this.validaDatosFis();

                 this.errores = false;
                 this.erroresGenerales = "";
                 this.loading = false;
                 this.loaderService.isLoading.next(false);
             }, err => {
             this.errores = true;
             this.erroresGenerales = "(T) Error al obtener datos de ciudades";
             this.loading = false;
             this.loaderService.isLoading.next(false);
         });
            
            this.errores = false;
            this.erroresGenerales = "";
            this.loading = false;
            this.loaderService.isLoading.next(false);
        }, err => {
        this.errores = true;
        this.erroresGenerales = "(T) Error al obtener datos de estados";
        this.loading = false;
        this.loaderService.isLoading.next(false);
    });
  }
  
  
  recuperarCodigoSMSRe(){
    (<HTMLInputElement>document.getElementById('codacttelTotal')).value = '';
    
    this.recuperarCodigoSMS();
  }
  
  
  recuperarCodigoSMS(){
    
    let data = {
      numCelular: this.telefonoControl.value,
      nombreCliente: this.telefonoControl.value,
      token: ''
    };  
    
    this.loading = true;
    this.loaderService.isLoading.next(true);
    this.errores = false;
    this.erroresGenerales = "";
    
    this.cuentaservice.getCodigo(data).subscribe((rs: any) => {
       this.loading = false;
       this.loaderService.isLoading.next(false);
       this.codigoSMS = rs.codigoSMS;
       this.secondsFull = rs.minutos; 
       
        clearInterval(this.clock);
        
        this.clock = setInterval(() => {
            this.secondsFull  = this.secondsFull - 1;

            if(this.secondsFull >= 0)
            {
                this.minutes = Math.floor(this.secondsFull / 60);
                this.seconds = this.secondsFull - (this.minutes * 60);

                this.minutesString =  this.minutes.toString().padStart(2, '0');
                this.secondsString =  this.seconds.toString().padStart(2, '0');
            }
            
            if(this.secondsFull <= 0)
            {
                clearInterval(this.clock);
            }
          },1000);
        
       this.step = 4;
    }, err => {
            this.loading = false;
            this.loaderService.isLoading.next(false);
            this.errores = true;
            this.erroresGenerales = "Error al generar el código";
        }
    );
  }
  
  saveStep1(){
    let data = {
      pasoActual: 6,
      id: this.idRegistro,
      correoElectronico: this.emailControl.value,
      titPrimerNombre: this.primerNombreRepControl.value,
      titSegundoNombre: this.segundoNombreRepControl.value,
      titApellidoPaterno: this.apellidoPaternoRepControl.value,
      titApellidoMaterno: this.apellidoMaternoRepControl.value,
      titFechaNacimiento: this.fechaNacimiento,
      titRfc: this.rfcTitControl.value,
      titCurp: this.rfcCurpControl.value,
      titTelefono: this.telefonoControl.value,
      titCalle: this.direccionCalleRepControl.value, 
      titNumero: this.direccionNumeroRepControl.value,
      titCodigoPostal: this.direccionCPRepControl.value,
      titColonia: this.coloniaRep,
      titPais: this.paisRep,
      titEstado: this.entidadFederativaRep,
      titCiudad: this.ciudadRep
    };  
    
    this.loading = true;
    this.loaderService.isLoading.next(true);
    this.errores = false;
    this.erroresGenerales = "";
    
    this.cuentaservice.saveRegistro1(data).subscribe((rs: any) => {
    
       if(rs.result)
       {
            this.errores = false;
            this.erroresGenerales = "";

            this.paso1estilo = 'done';
            this.paso1icono = 'zmdi-collection-item-1check';
            this.paso2estilo = 'done';
            this.paso2icono = 'zmdi-collection-item-2check';
            this.paso3estilo = 'current';
            this.paso3icono = 'zmdi-collection-item-3s';
            this.paso4estilo = '';
            this.paso4icono = 'zmdi-collection-item-4s';
            this.paso5estilo = '';
            this.paso5icono = 'zmdi-collection-item-5s';

            this.step = 6;
            
            this.loading = false;
            this.loaderService.isLoading.next(false);
       }
       else
       {
            this.erroresGenerales = rs.message;
            this.errores = true;
            this.loading = false;
            this.loaderService.isLoading.next(false);
       }
    }, err => {
            this.erroresGenerales = "Error al guardar el paso 1";
            this.errores = true;
            this.loading = false;
            this.loaderService.isLoading.next(false);
        }
    );
  }
  
  saveStep2(){
    let data = {
      pasoActual: 7,
      correoElectronico: this.emailControl.value,
      id: this.idRegistro,
      comNombreComercial: this.nombreComercialControl.value,
      comSitioWeb: this.sitioWebControl.value,
      comRedesSociales: this.redesSocialesControl.value,
      comGiro: this.giro,
      comMoneda: this.moneda,
      comTipoEmpresa: this.tipoEmpresa, 
      comFisIguPer: this.opcionDireccionFis
    };  
    
    this.loading = true;
    this.loaderService.isLoading.next(true);
    this.errores = false;
    this.erroresGenerales = "";
    
    this.cuentaservice.saveRegistro2(data).subscribe((rs: any) => {
       
       if(rs.result)
       {
            this.errores = false;
            this.erroresGenerales = "";

            this.step = 7;

            this.mostrarNombre = false;
            this.mostrarRazon = false;
            this.mostrarDirFis = true;

            if(this.tipoEmpresa == "1")
            {
                this.mostrarNombre = true;
                this.mostrarDirFis = false;
            }
            else
            {
                 this.mostrarRazon = true;
            }            

            this.loading = false;
            this.loaderService.isLoading.next(false);
       }
       else
       {
            this.erroresGenerales = rs.message;
            this.errores = true;
            this.loading = false;
            this.loaderService.isLoading.next(false);
       }
    }, err => {
            this.erroresGenerales = "Error al guardar el paso 2";
            this.errores = true;
            this.loading = false;
            this.loaderService.isLoading.next(false);
        }
    );
  }
  
  saveStep3(){
    let data = {
      pasoActual: 8,
      correoElectronico: this.emailControl.value,
      id: this.idRegistro,
      fisPrimerNombre: this.primerNombreFisControl.value,
      fisSegundoNombre: this.segundoNombreFisControl.value,
      fisApellidoPaterno: this.apellidoPaternoFisControl.value,
      fisApellidoMaterno: this.apellidoMaternoFisControl.value,
      fisRazonSocial: this.razonSocialFisControl.value,
      fisFechaAlta: this.fechaAlta,
      fisRfc: this.rfcFisControl.value,
      fisCurp: this.rfcCurpFisControl.value,
      fisTelefono: this.telefonoFisControl.value,
      fisCalle: this.direccionCalleFisControl.value, 
      fisNumero: this.direccionNumeroFisControl.value,
      fisCodigoPostal: this.direccionCPFisControl.value,
      fisColonia: this.coloniaFis,
      fisPais: this.paisFis,
      fisEstado: this.entidadFederativaFis,
      fisCiudad: this.ciudadFis
    };  
    
    this.loading = true;
    this.loaderService.isLoading.next(true);
    this.errores = false;
    this.erroresGenerales = "";
    
    this.cuentaservice.saveRegistro3(data).subscribe((rs: any) => {
       
       if(rs.result)
       {
            this.errores = false;
            this.erroresGenerales = "";

            this.paso1estilo = 'done';
            this.paso1icono = 'zmdi-collection-item-1check';
            this.paso2estilo = 'done';
            this.paso2icono = 'zmdi-collection-item-2check';
            this.paso3estilo = 'done';
            this.paso3icono = 'zmdi-collection-item-3check';
            this.paso4estilo = 'current';
            this.paso4icono = 'zmdi-collection-item-4s';
            this.paso5estilo = '';
            this.paso5icono = 'zmdi-collection-item-5s';

            this.step = 8;
            
            this.loading = false;
            this.loaderService.isLoading.next(false);
       }
       else
       {
            this.erroresGenerales = rs.message;
            this.errores = true;
            this.loading = false;
            this.loaderService.isLoading.next(false);
       }
    }, err => {
            this.erroresGenerales = "Error al guardar el paso 3";
            this.errores = true;
            this.loading = false;
            this.loaderService.isLoading.next(false);
        }
    );
  }
  
  saveStep4(){
    let data = {
      pasoActual: 9,
      correoElectronico: this.emailControl.value,
      id: this.idRegistro,
      transVolumen: this.volumenTrans,
      transTicketPromedio: this.ticketControl.value,
      transResidencia: this.paisCliente,
      transHorarios: this.horariosControl.value,
      transFacturacionMensual: this.facturacionControl.value,
      transMontoDiario: this.montodiarioTrans,
      transRangoPrecios: this.rangoPrecioTrans,
      transBanco: this.bancoTrans,
      transCuenta: this.cuentaControl.value,
      transClabe: this.clabeControl.value,
      transMesesSinIntereses: this.mesesSinInteres
    };  
    
    this.loading = true;
    this.loaderService.isLoading.next(true);
    this.errores = false;
    this.erroresGenerales = "";
    
    this.cuentaservice.saveRegistro4(data).subscribe((rs: any) => {
       
       if(rs.result)
       {
            this.errores = false;
            this.erroresGenerales = "";

            this.paso1estilo = 'done';
            this.paso1icono = 'zmdi-collection-item-1check';
            this.paso2estilo = 'done';
            this.paso2icono = 'zmdi-collection-item-2check';
            this.paso3estilo = 'done';
            this.paso3icono = 'zmdi-collection-item-3check';
            this.paso4estilo = 'done';
            this.paso4icono = 'zmdi-collection-item-4check';
            this.paso5estilo = 'current';
            this.paso5icono = 'zmdi-collection-item-5s';

            this.step = 9;

            this.loading = false;
            this.loaderService.isLoading.next(false);
       }
       else
       {
            this.erroresGenerales = rs.message;
            this.errores = true;
            this.loading = false;
            this.loaderService.isLoading.next(false);
       }
    }, err => {
            this.erroresGenerales = "Error al guardar el paso 4";
            this.errores = true;
            this.loading = false;
            this.loaderService.isLoading.next(false);
        }
    );
  }
  
  saveStep5(){
    let data = {
      pasoActual: 10,
      correoElectronico: this.emailControl.value,
      id: this.idRegistro,
      doc1: this.name,
      doc2: this.name2,
      doc3: this.name3,
      doc4: this.name4,
      doc5: this.name5,
      doc6: this.name6,
      doc7: this.name7,
      doc8: this.name8
    };  
    
    this.loading = true;
    this.loaderService.isLoading.next(true);
    this.errores = false;
    this.erroresGenerales = "";
    
    this.cuentaservice.saveRegistro5(data).subscribe((rs: any) => {
       if(rs.result)
       {
            this.errores = false;
            this.erroresGenerales = "";
            this.warnings = false;
            this.warningsGenerales = "";
            this.successmsg = false;
            this.successmsgGenerales = "";
            
            this.paso1estilo = 'done';
            this.paso1icono = 'zmdi-collection-item-1check';
            this.paso2estilo = 'done';
            this.paso2icono = 'zmdi-collection-item-2check';
            this.paso3estilo = 'done';
            this.paso3icono = 'zmdi-collection-item-3check';
            this.paso4estilo = 'done';
            this.paso4icono = 'zmdi-collection-item-4check';
            this.paso5estilo = 'done';
            this.paso5icono = 'zmdi-collection-item-5check';

            this.correoFinal = this.emailControl.value;
            this.numeroSeguimientoFinal = rs.folio;

            this.step = 10;

            this.loading = false;
            this.loaderService.isLoading.next(false);
       }
       else
       {
            this.erroresGenerales = rs.message;
            this.errores = true;
            this.successmsg = false;
            this.successmsgGenerales = "";
            
            this.loading = false;
            this.loaderService.isLoading.next(false);
       }
    }, err => {
            this.erroresGenerales = "Error al guardar el paso 5";
            this.errores = true;
            this.successmsg = false;
            this.successmsgGenerales = "";
            this.loading = false;
            this.loaderService.isLoading.next(false);
        }
    );
  }
  
  
  saveStep5a(){
    let data = {
      pasoActual: 9,
      correoElectronico: this.emailControl.value,
      id: this.idRegistro,
      doc1: this.name,
      doc2: this.name2,
      doc3: this.name3,
      doc4: this.name4,
      doc5: this.name5,
      doc6: this.name6,
      doc7: this.name7,
      doc8: this.name8
    };  
    
    this.loading = true;
    this.loaderService.isLoading.next(true);
    this.errores = false;
    this.erroresGenerales = "";
    this.successmsg = false;
    this.successmsgGenerales = "";

    this.cuentaservice.saveRegistro5(data).subscribe((rs: any) => {
       if(rs.result)
       {
            this.successmsg = true;
            this.successmsgGenerales = "Información guardada";

            this.loading = false;
            this.loaderService.isLoading.next(false);
       }
       else
       {
            this.erroresGenerales = rs.message;
            this.errores = true;
            this.successmsg = false;
            this.successmsgGenerales = "";

            this.loading = false;
            this.loaderService.isLoading.next(false);
       }
    }, err => {
            this.erroresGenerales = "Error al guardar el paso 5";
            this.errores = true;
            this.successmsg = false;
            this.successmsgGenerales = "";

            this.loading = false;
            this.loaderService.isLoading.next(false);
        }
    );
  }
  
  saveCodigo(ireinicio){
    let data = {
      email: this.emailControl.value,
      codigoEmbajadora: '',
      reinicio: ireinicio
    };  
    
    this.loading = true;
    this.loaderService.isLoading.next(true);
    this.errores = false;
    this.erroresGenerales = "";
    
    this.cuentaservice.saveCodigo(data).subscribe((rs: any) => {
       if(rs.result)
       {
            this.idRegistro  = rs.idRegistro;
            if(ireinicio == 1)
            {
                this.errores = false;
                this.erroresGenerales = "";

                this.paso1estilo = 'done';
                this.paso1icono = 'zmdi-collection-item-1check';
                this.paso2estilo = 'current';
                this.paso2icono = 'zmdi-collection-item-2s';
                this.paso3estilo = '';
                this.paso3icono = 'zmdi-collection-item-3s';
                this.paso4estilo = '';
                this.paso4icono = 'zmdi-collection-item-4s';
                this.paso5estilo = '';
                this.paso5icono = 'zmdi-collection-item-5s';

                this.step = 5;

                this.loading = false;
                this.loaderService.isLoading.next(false);
            } else {
                this.cuentaservice.getRegistro(rs.idRegistro).subscribe((rs: any) => {
                    if(rs.result)
                    {
                        this.errores = false;
                        this.erroresGenerales = "";

                        if(rs.estatus == 1)
                        {
                            if(rs.pasoActual >= 6)
                            {
                                this.primerNombreRepControl.setValue(rs.titPrimerNombre);
                                this.segundoNombreRepControl.setValue(rs.titSegundoNombre);
                                this.apellidoPaternoRepControl.setValue(rs.titApellidoPaterno);
                                this.apellidoMaternoRepControl.setValue(rs.titApellidoMaterno);
                                this.fechaNacimiento = rs.titFechaNacimiento;
                                this.rfcTitControl.setValue(rs.titRfc);
                                this.rfcCurpControl.setValue(rs.titCurp);
                                this.telefonoControl.setValue(rs.titTelefono);
                                this.direccionCalleRepControl.setValue(rs.titCalle);
                                this.direccionNumeroRepControl.setValue(rs.titNumero);
                                this.direccionCPRepControl.setValue(rs.titCodigoPostal);
                                this.coloniaRep = rs.titColonia;
                                this.cpCapturadoInterno(this.coloniaRep);
                                this.paisRep = rs.titPais;
                                this.entidadFederativaRep = rs.titEstado;
                                this.ciudadRep = rs.titCiudad;

                                this.disabledNextRep = false;

                                this.paso1estilo = 'done';
                                this.paso1icono = 'zmdi-collection-item-1check';
                                this.paso2estilo = 'done';
                                this.paso2icono = 'zmdi-collection-item-2check';
                                this.paso3estilo = 'current';
                                this.paso3icono = 'zmdi-collection-item-3s';
                                this.paso4estilo = '';
                                this.paso4icono = 'zmdi-collection-item-4s';
                                this.paso5estilo = '';
                                this.paso5icono = 'zmdi-collection-item-5s';

                            }
                            if(rs.pasoActual >= 7)
                            {
                                this.nombreComercialControl.setValue(rs.comNombreComercial);
                                this.sitioWebControl.setValue(rs.comSitioWeb);
                                this.redesSocialesControl.setValue(rs.comRedesSociales);
                                this.giro = rs.comGiro;
                                this.moneda = rs.comMoneda;
                                this.tipoEmpresa = rs.comTipoEmpresa;
                                this.opcionDireccionFis = rs.comFisIguPer;
                                
                                this.mostrarNombre = false;
                                this.mostrarRazon = false;
                                this.mostrarDirFis = true;

                                if(this.tipoEmpresa == "1")
                                {
                                    this.mostrarNombre = true;
                                    this.mostrarDirFis = false;
                                    this.mostrarDireccion = true;
                                }
                                else
                                {
                                     this.mostrarRazon = true;
                                     this.mostrarDireccion = false;
                                }
                               
                                this.disabledNextCom = false;
                            }
                            if(rs.pasoActual >= 8)
                            {
                                this.primerNombreFisControl.setValue(rs.fisPrimerNombre);
                                this.segundoNombreFisControl.setValue(rs.fisSegundoNombre);
                                this.apellidoPaternoFisControl.setValue(rs.fisApellidoPaterno);
                                this.apellidoMaternoFisControl.setValue(rs.fisApellidoMaterno);
                                this.razonSocialFisControl.setValue(rs.fisRazonSocial);
                                this.fechaAlta = rs.fisFechaAlta;
                                this.rfcFisControl.setValue(rs.fisRfc);
                                this.rfcCurpFisControl.setValue(rs.fisCurp);
                                this.telefonoFisControl.setValue(rs.fisTelefono);
                                this.direccionCalleFisControl.setValue(rs.fisCalle);
                                this.direccionNumeroFisControl.setValue(rs.fisNumero);
                                this.direccionCPFisControl.setValue(rs.fisCodigoPostal);
                                this.coloniaFis = rs.fisColonia;
                                this.cpFisCapturadoInterno(this.coloniaFis);
                                this.paisFis = rs.fisPais;
                                this.entidadFederativaFis = rs.fisEstado;
                                this.ciudadFis = rs.fisCiudad;
                           
                                this.disabledNextRep2 = false;
                                
                                this.paso1estilo = 'done';
                                this.paso1icono = 'zmdi-collection-item-1check';
                                this.paso2estilo = 'done';
                                this.paso2icono = 'zmdi-collection-item-2check';
                                this.paso3estilo = 'done';
                                this.paso3icono = 'zmdi-collection-item-3check';
                                this.paso4estilo = 'current';
                                this.paso4icono = 'zmdi-collection-item-4s';
                                this.paso5estilo = '';
                                this.paso5icono = 'zmdi-collection-item-5s';

                            }
                            if(rs.pasoActual >= 9)
                            {
                                this.volumenTrans = rs.transVolumen;
                                this.ticketControl.setValue(rs.transTicketPromedio);
                                this.paisCliente = rs.transResidencia;
                                this.horariosControl.setValue(rs.transHorarios);
                                this.facturacionControl.setValue(rs.transFacturacionMensual);
                                this.montodiarioTrans = rs.transMontoDiario;
                                this.rangoPrecioTrans = rs.transRangoPrecios;
                                this.bancoTrans = rs.transBanco;
                                this.cuentaControl.setValue(rs.transCuenta);
                                this.clabeControl.setValue(rs.transClabe);
                                this.mesesSinInteres = rs.transMesesSinIntereses;
  
                                this.name = rs.doc1;
                                this.name2 = rs.doc2;
                                this.name3 = rs.doc3;
                                this.name4 = rs.doc4;
                                this.name5 = rs.doc5;
                                this.name6 = rs.doc6;
                                this.name7 = rs.doc7;
                                this.name8 = rs.doc8;
                                
                                
                                this.disabledNextTrans = false;
                                this.disabledNextDoc = true;
                                
                                if(this.name != null && this.name2 != null && this.name3 != null &&
                                    this.name4 != null && this.name5 != null && this.name6 != null &&
                                    this.name7 != null && this.name != null)
                                {
                                    if(this.name.trim().length > 0 && this.name2.trim().length > 0 &&
                                        this.name3.trim().length > 0 && this.name4.trim().length > 0 &&
                                        this.name5.trim().length > 0 && this.name6.trim().length > 0 &&
                                        this.name7.trim().length > 0 && this.name8.trim().length > 0)
                                    {
                                        this.disabledNextDoc = false;
                                    }
                                }
                                
                                this.paso1estilo = 'done';
                                this.paso1icono = 'zmdi-collection-item-1check';
                                this.paso2estilo = 'done';
                                this.paso2icono = 'zmdi-collection-item-2check';
                                this.paso3estilo = 'done';
                                this.paso3icono = 'zmdi-collection-item-3check';
                                this.paso4estilo = 'done';
                                this.paso4icono = 'zmdi-collection-item-4check';
                                this.paso5estilo = 'current';
                                this.paso5icono = 'zmdi-collection-item-5s';

                            }
                            if(rs.pasoActual == 10)
                            {
                                this.name = rs.doc1;
                                this.name2 = rs.doc2;
                                this.name3 = rs.doc3;
                                this.name4 = rs.doc4;
                                this.name5 = rs.doc5;
                                this.name6 = rs.doc6;
                                this.name7 = rs.doc7;
                                this.name8 = rs.doc8;
                                
                                this.disabledNextRep = false;
                                this.disabledNextCom = false;
                                this.disabledNextRep2 = false;
                                this.disabledNextTrans = false;
                                this.disabledNextDoc = false;
                                
                                this.paso1estilo = 'done';
                                this.paso1icono = 'zmdi-collection-item-1check';
                                this.paso2estilo = 'done';
                                this.paso2icono = 'zmdi-collection-item-2check';
                                this.paso3estilo = 'done';
                                this.paso3icono = 'zmdi-collection-item-3check';
                                this.paso4estilo = 'done';
                                this.paso4icono = 'zmdi-collection-item-4check';
                                this.paso5estilo = 'done';
                                this.paso5icono = 'zmdi-collection-item-5check';

                            }
                            
                            this.step = rs.pasoActual;
                        } 
                        if(rs.estatus == 2)
                        {
                            this.paso1estilo = 'done';
                            this.paso1icono = 'zmdi-collection-item-1check';
                            this.paso2estilo = 'done';
                            this.paso2icono = 'zmdi-collection-item-2check';
                            this.paso3estilo = 'done';
                            this.paso3icono = 'zmdi-collection-item-3check';
                            this.paso4estilo = 'done';
                            this.paso4icono = 'zmdi-collection-item-4check';
                            this.paso5estilo = 'done';
                            this.paso5icono = 'zmdi-collection-item-5check';

                            
                            this.correoFinal = rs.correoElectronico;
                            this.numeroSeguimientoFinal = rs.folio;
                            this.step = 600;
                        }   
                        if(rs.estatus == 3)
                        {
                            this.paso1estilo = 'done';
                            this.paso1icono = 'zmdi-collection-item-1check';
                            this.paso2estilo = 'done';
                            this.paso2icono = 'zmdi-collection-item-2check';
                            this.paso3estilo = 'done';
                            this.paso3icono = 'zmdi-collection-item-3check';
                            this.paso4estilo = 'done';
                            this.paso4icono = 'zmdi-collection-item-4check';
                            this.paso5estilo = 'done';
                            this.paso5icono = 'zmdi-collection-item-5check';
                            
                            this.correoFinal = rs.correoElectronico;
                            this.numeroSeguimientoFinal = rs.folio;
                            this.step = 700;
                        }
                        if(rs.estatus == 4)
                        {
                            this.primerNombreRepControl.setValue(rs.titPrimerNombre);
                            this.segundoNombreRepControl.setValue(rs.titSegundoNombre);
                            this.apellidoPaternoRepControl.setValue(rs.titApellidoPaterno);
                            this.apellidoMaternoRepControl.setValue(rs.titApellidoMaterno);
                            this.fechaNacimiento = rs.titFechaNacimiento;
                            this.rfcTitControl.setValue(rs.titRfc);
                            this.rfcCurpControl.setValue(rs.titCurp);
                            this.telefonoControl.setValue(rs.titTelefono);
                            this.direccionCalleRepControl.setValue(rs.titCalle);
                            this.direccionNumeroRepControl.setValue(rs.titNumero);
                            this.direccionCPRepControl.setValue(rs.titCodigoPostal);
                            this.coloniaRep =  rs.titColonia;
                            this.cpCapturadoInterno(this.coloniaRep);
                            this.paisRep = rs.titPais;
                            this.entidadFederativaRep = rs.titEstado;
                            this.ciudadRep = rs.titCiudad;
                            this.nombreComercialControl.setValue(rs.comNombreComercial);
                            this.sitioWebControl.setValue(rs.comSitioWeb);
                            this.redesSocialesControl.setValue(rs.comRedesSociales);
                            this.giro = rs.comGiro;
                            this.moneda = rs.comMoneda;
                            this.tipoEmpresa = rs.comTipoEmpresa;
                            this.opcionDireccionFis = rs.comFisIguPer;
                            this.primerNombreFisControl.setValue(rs.fisPrimerNombre);
                            this.segundoNombreFisControl.setValue(rs.fisSegundoNombre);
                            this.apellidoPaternoFisControl.setValue(rs.fisApellidoPaterno);
                            this.apellidoMaternoFisControl.setValue(rs.fisApellidoMaterno);
                            this.razonSocialFisControl.setValue(rs.fisRazonSocial);
                            this.fechaAlta = rs.fisFechaAlta;
                            this.rfcFisControl.setValue(rs.fisRfc);
                            this.rfcCurpFisControl.setValue(rs.fisCurp);
                            this.telefonoFisControl.setValue(rs.fisTelefono);
                            this.direccionCalleFisControl.setValue(rs.fisCalle);
                            this.direccionNumeroFisControl.setValue(rs.fisNumero);
                            this.direccionCPFisControl.setValue(rs.fisCodigoPostal);
                            this.coloniaFis = rs.fisColonia;
                            this.cpFisCapturadoInterno(this.coloniaFis);
                            this.paisFis = rs.fisPais;
                            this.entidadFederativaFis = rs.fisEstado;
                            this.ciudadFis = rs.fisCiudad;
                            this.volumenTrans = rs.transVolumen;
                            this.ticketControl.setValue(rs.transTicketPromedio);
                            this.paisCliente = rs.transResidencia;
                            this.horariosControl.setValue(rs.transHorarios);
                            this.facturacionControl.setValue(rs.transFacturacionMensual);
                            this.montodiarioTrans = rs.transMontoDiario;
                            this.rangoPrecioTrans = rs.transRangoPrecios;
                            this.bancoTrans = rs.transBanco;
                            this.cuentaControl.setValue(rs.transCuenta);
                            this.clabeControl.setValue(rs.transClabe);
                            this.mesesSinInteres = rs.transMesesSinIntereses;
                            this.name = rs.doc1;
                            this.name2 = rs.doc2;
                            this.name3 = rs.doc3;
                            this.name4 = rs.doc4;
                            this.name5 = rs.doc5;
                            this.name6 = rs.doc6;
                            this.name7 = rs.doc7;
                            this.name8 = rs.doc8;

                            this.mostrarNombre = false;
                            this.mostrarRazon = false;
                            this.mostrarDirFis = true;
 
                            if(this.tipoEmpresa == "1")
                            {
                                this.mostrarNombre = true;
                                this.mostrarDirFis = false;
                                this.mostrarDireccion = true;
                            }
                            else
                            {
                                 this.mostrarRazon = true;
                                 this.mostrarDireccion = false;
                            }

                            this.paso1estilo = 'done';
                            this.paso1icono = 'zmdi-collection-item-1check';
                            this.paso2estilo = 'done';
                            this.paso2icono = 'zmdi-collection-item-2check';
                            this.paso3estilo = 'done';
                            this.paso3icono = 'zmdi-collection-item-3check';
                            this.paso4estilo = 'done';
                            this.paso4icono = 'zmdi-collection-item-4check';
                            this.paso5estilo = 'done';
                            this.paso5icono = 'zmdi-collection-item-5check';

                            this.observacionesRechazo = rs.comentarios;
                            
                            this.disabledNextRep = false;
                            this.disabledNextCom = false;
                            this.disabledNextRep2 = false;
                            this.disabledNextTrans = false;
                            this.disabledNextDoc = false;
                            
                            this.correoFinal = rs.correoElectronico;
                            this.numeroSeguimientoFinal = rs.folio;
                            this.step = 800;
                        }
                        
                        this.loading = false;
                        this.loaderService.isLoading.next(false);
                    }
                    else
                    {
                         this.erroresGenerales = rs.message;
                         this.errores = true;
                         this.loading = false;
                         this.loaderService.isLoading.next(false);
                    }
                 }, err => {
                         this.erroresGenerales = "Error al obtener informacion (2)";
                         this.errores = true;
                         this.loading = false;
                         this.loaderService.isLoading.next(false);
                     }
                 );
            }      
       }
       else
       {
            this.erroresGenerales = rs.message;
            this.errores = true;
            this.loading = false;
            this.loaderService.isLoading.next(false);
       }
    }, err => {
            this.erroresGenerales = "Error al validar el código";
            this.errores = true;
            this.loading = false;
            this.loaderService.isLoading.next(false);
        }
    );
  }

}
