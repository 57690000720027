import {Component, OnInit, OnDestroy} from '@angular/core';
import {LoaderService} from '../services/loader.service';
import {MatDialog} from '@angular/material/dialog';
import {ModalLoadingComponent} from './ModalLoading.component';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-loader',
  template: ``,
})
export class LoaderComponent implements OnInit, OnDestroy {
  loading = false;
  loader: any;
  private subscription: Subscription = new Subscription();
  constructor(private loaderService: LoaderService, public dialog: MatDialog ) {
    this.subscription.add(this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
      if (this.loading) {
        if (this.loader === undefined) {
          this.loader = this.dialog.open(ModalLoadingComponent, {
            disableClose: true,
            width: '12rem',
            height: '12rem',
            panelClass: 'loading-new',
            autoFocus: false
          });
        }
      } else {
        if (this.loader !== undefined) {
          this.loader.close();
          this.loader.afterClosed().subscribe((result:any) => {
            this.loader = undefined;
          });
        }
      }
    }));
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
