import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PickDateAdapter, PICK_FORMATS } from './helpers/custom-date-adapter';

import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox'; 
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { MoveNextByMaxLengthDirective } from './helpers/move-next-bymax';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './interceptors/loader-interceptor.service';
import { LoaderComponent } from './components/loader.component';
import { ModalLoadingComponent } from './components/ModalLoading.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { A11yModule } from "@angular/cdk/a11y";
import { registerLocaleData } from '@angular/common';
import { BlockCopyPasteDirective } from './directives/block-copy-paste.directive';

import localeEs from '@angular/common/locales/es';
import { PasswordStrengthBarComponent } from './components/password-strength-bar/password-strength-bar.component';
registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    ModalLoadingComponent,
    MoveNextByMaxLengthDirective,
    BlockCopyPasteDirective,
    PasswordStrengthBarComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatDialogModule,
    HttpClientModule,
    StoreModule.forRoot({}),
    A11yModule
  ],
  providers: [
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: DateAdapter, useClass: PickDateAdapter},
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS},
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }