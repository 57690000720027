import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
selector: '[moveNextByMaxLength]'
})
export class MoveNextByMaxLengthDirective {

constructor(private _el: ElementRef) { }

@HostListener('keyup', ['$event']) onKeyDown(e: any) {
    if (e.key !== 'Tab' && e.key !== 'Shift' && e.key !== 'Backspace' && e.key !== 'Enter') {
      e.preventDefault();
      if (e.srcElement.maxLength === e.srcElement.value.length) {
        let nextControl: any = e.target.nextElementSibling;
        // Searching for next similar control to set it focus
        while (true)
        {
          if (nextControl)
          {
            if (nextControl.type === e.target.type)
            {
              nextControl.focus();
              return;
            }
            else
            {
              nextControl = nextControl.nextElementSibling;
            }
          }
          else
          {
            var btn2_3 = document.getElementById('btn2_3');

            if(btn2_3)
            {
                btn2_3.focus();
            }

            var btn2_2 = document.getElementById('btn2_2');

            if(btn2_2)
            {
                btn2_2.focus();
            }

            return;
          }
        }
      } 
    } else if(e.key === 'Backspace' && e.srcElement.value.length === 0) {
      e.preventDefault();
      let prevControl: any = e.target.previousElementSibling;
      // Searching for next similar control to set it focus
      while (true)
      {
        if (prevControl)
        {
          if (prevControl.type === e.target.type)
          {
            prevControl.focus();
            return;
          }
          else
          {
            prevControl = prevControl.prevElementSibling;
          }
        }
        else
        {
          return;
        }
      }
    }
}
}